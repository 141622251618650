import {
  Particle
} from "./particle.js";
import {colors} from '../colors/colors.js';

export class Particles {
  constructor(color, performance) {

    if (performance === 'high') {
      this.totalParticles = 15;
    } else if (performance === 'medium') {
      this.totalParticles = 5;
    } else {
      this.totalParticles = 0;
    }

    let colorIndex = colors.findIndex(item => item.value === color );

    //this.color = ['#ff0000', 'rgba(52,211,153,0.1)', 'rgba(5,150,105,0.1)'];
    this.color = [
      '#' + colors[colorIndex].colors[100],
      '#' + colors[colorIndex].colors[500],
      '#' + colors[colorIndex].colors[600],
      '#' + colors[colorIndex].colors[700],
      '#' + colors[colorIndex].colors[800],
      '#' + colors[colorIndex].colors[900],
    ];

    this.particles = [];

    for (let i = 0; i < this.totalParticles; i++) {
      const particle = new Particle (
          i,
          this.color[Math.floor(Math.random() * (this.color.length-1))],
      );
      this.particles[i] = particle;
    }
  }

  resize (stageWidth, stageHeight) {
    for (let i = 0; i < this.totalParticles; i++) {
      const particle = this.particles[i];
      particle.resize(stageWidth, stageHeight);
    }
  }

  draw(ctx, bootstrap) {
    for (let i = 0; i < this.totalParticles; i++) {
      const particle = this.particles[i];
      particle.draw(ctx, bootstrap);
    }
  }
}
