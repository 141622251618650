import {Wave} from './wave.js';
import {colors} from '../colors/colors.js';

export class WaveGroup {
  constructor(color, performance) {
    if (performance === 'high') {
      this.totalWaves = 3;
      this.totalPoints = 6;
    } else if (performance === 'medium') {
      this.totalWaves = 3;
      this.totalPoints = 4;
    } else {
      this.totalWaves = 3;
      this.totalPoints = 3;
    }

    let colorIndex = colors.findIndex(item => item.value === color );

    this.color = [
      '#' + colors[colorIndex].colors[200],
      '#' + colors[colorIndex].colors[400],
      '#' + colors[colorIndex].colors[500]
    ];

    this.waves = [];

    for (let i = 0; i < this.totalWaves; i++) {
      const wave = new Wave (
        i,
        this.totalPoints,
        this.color[i],
        90
      );
      this.waves[i] = wave;
    }
  }

  resize (stageWidth, stageHeight) {
    this.stageWidth = stageWidth;
    this.stageHeight = stageHeight;
    for (let i = 0; i < this.totalWaves; i++) {
      const wave = this.waves[i];
      wave.resize(stageWidth, stageHeight);
    }
  }

  draw(ctx, posY, active, bootstrap, performance) {
    if ((active || bootstrap) && performance !== 'low') {
      for (let i = 0; i < this.totalWaves; i++) {
        const wave = this.waves[i];
        wave.draw(ctx, posY, bootstrap);
      }
    } else {
      ctx.beginPath();
      ctx.fillStyle = this.color[2];
      ctx.rect(0, posY, this.stageWidth, this.stageHeight);
      ctx.fill();
    }
  }
}
