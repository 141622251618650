<template>
  <div
    class="col-span-1 flex flex-col text-center bg-white rounded-xl shadow-xl overflow-hidden relative border-4"
    :class="['block-component bg-' + color + '-100', 'border-' + color + '-900', {'opacity-0': isNew}]"
    :style="styleObject"
    :id="'block-' + id"
  >
    <div v-if="hasError"
         class="absolute w-full h-full bg-red-200 opacity-90 z-50 text-red-900 font-medium pt-5"
    >
      Oh no, something went wrong!<br />Please <a @click="reload()" class="underline cursor-pointer">reload</a>.
    </div>

    <canvas :key="particlesKey" class="absolute w-full h-full z-10" :id="'canvas-' + id" :elapsed-time="elapsedTime" :size="size"></canvas>

    <div class="absolute z-10 w-full h-full">
      <div class="rounded-md h-full opacity-20 border-3"
           style="border-radius: 8px;"
           :style="'box-shadow: inset 0 0 100px 0 #' + colorDeep + '; border-color: #' + colorLight">
      </div>
    </div>

    <div
      class="z-20 border-b-2 p-4 pb-0 relative shadow-none bg-transparent"
      :class="['border-' + color + '-300', {'pb-4': !projectRef}]"
    >
      <svg
        @click="blockEdit"
        :key="titleKey"
        class="h-1 cursor-pointer z-10 text-gray-900 text-2xl font-bold inline-block"
        style="font-family: 'Roboto Mono', monospace;width: 100%; height: 32px;">
        <text :style="'stroke: #' + colorOutline + ';'" text-anchor="middle" x="50%" y="28" fill="white" stroke-width="5" letter-spacing="3" paint-order="stroke">{{formattedElapsedTime}}</text>
      </svg>
      <span
        @click="blockEdit"
        style=""
        class="cursor-pointer z-10 text-md font-bold inline-block"
        :class="'text-' + color + '-900'"
      >
        {{title}}
      </span>
      <div v-if="projectRef" @click="blockEdit" class="cursor-pointer text-xs">
        <div class="w-2 h-2 inline-block rounded-full" :class="'bg-' + projectObj.color + '-400'"></div>
        {{projectObj.name}}
      </div>

      <button v-if="buildMode" @click="blockEdit" class="z-20 top-1 left-1 absolute opacity-50 hover:opacity-100 rounded-md inline-flex text-gray-900 hover:text-gray-1000 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
        :class="'text-' + color + '-700 hover:text-' + color + '-900'">
        <svg class="w-4 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"></path><path fill-rule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clip-rule="evenodd"></path></svg>
      </button>

      <button v-if="buildMode"
        class="cursor-move block-drag-handle z-20 top-3 left-1/2 transform -translate-x-1/2 -translate-y-1/2 absolute opacity-50 hover:opacity-100 rounded-md inline-flex text-gray-900 hover:text-gray-1000 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
        :class="'text-' + color + '-700 hover:text-' + color + '-900'"
      >
        <svg class="w-6 h-6" stroke="currentColor" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"/><circle cx="5" cy="9" r="1" /><circle cx="5" cy="15" r="1" /><circle cx="12" cy="9" r="1" /><circle cx="12" cy="15" r="1" /><circle cx="19" cy="9" r="1" /><circle cx="19" cy="15" r="1" />
        </svg>
      </button>

      <button v-if="embed && local && !isActive" @click="reset"
        class="z-20 top-1 right-1 absolute opacity-50 hover:opacity-100 rounded-md inline-flex text-gray-900 hover:text-gray-1000 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
        :class="'text-' + color + '-700 hover:text-' + color + '-900'"
      >
        <span class="sr-only">Reset</span>
        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd" d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z" clip-rule="evenodd" />
        </svg>
      </button>

      <button v-if="buildMode" @click="deleteBlock"
        class="z-20 top-1 right-1 absolute opacity-50 hover:opacity-100 rounded-md inline-flex focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
        :class="'text-' + color + '-700 hover:text-' + color + '-900'"
      >
        <span class="sr-only">Close</span>
        <!-- Heroicon name: x -->
        <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
          <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
        </svg>
      </button>
    </div>
    <div
      class="flex-1 flex flex-col pb-4 block relative"
    >

      <div class="flex-1 flex flex-col px-8 z-20">
        <dl class="flex-grow flex flex-col justify-between">
          <dt class="sr-only">Title</dt>
          <dd class="text-gray-500 text-regular text-gray-900">
            <a
              @click="toggle"
              class="inline-flex items-center mt-4 mb-1"
              :class="playBallColor"
            >
            <BlockFace
              v-bind:isActive="isActive"
              v-bind:type="faceType"
              v-bind:color="color"
              v-bind:skin="skin"
              v-bind:performance="performance"
              v-bind:isLoading="isLoading"
              v-bind:hasError="hasError"
              v-bind:id="id"
            ></BlockFace>
            </a>
          </dd>
          <dt></dt>
          <dt class="sr-only"></dt>
          <dd class="mt-1">
            <span
              class="px-2 py-1 text-sm font-medium rounded-md"
              style="font-family: 'Roboto Mono', monospace;"
              :class="remainingTimeStyleObject"
            >{{formattedRemainingTime.value}}</span>
          </dd>
        </dl>
      </div>

      <div v-if="this.size < 2"
        :style="startStyleObject"
         class="absolute border-t-2 border-dashed opacity-80 w-full z-10"
         :class="'border-' + this.color + '-500'"
      >
      </div>
      <div
        v-for="element in elementsProcessed"
        class="absolute w-full z-10"
        :style="element.styleObject"
        v-bind:key="element.id"
        data-time=""
      >
        <div
          class="absolute block top-0 opacity-80"
          :class="'bg-' + color + '-500'"
          style="width: 4px; height: 1px;left:0px;"></div>
      </div>
    </div>
  </div>
</template>
<script>
  import {colors} from '../colors/colors.js';
  import {particles} from '../particles/particles.js';
  import BlockFace from './BlockFace.vue';
  import {v4 as uuidv4} from "uuid";
  import { useToast } from "vue-toastification";
  import {Canvas} from '../canvas/canvas.js';
  import { nextTick } from 'vue';
  import anime from "animejs";

  export default {
    name: 'Block',
    components: {
      BlockFace,
    },
    props: {
      id: String,
      title: String,
      color: String,
      projectRef: {
        default: null,
        type: String
      },
      skin: {
        default: 'neutral',
        type: String
      },
      size: Number,
      src: String,
      faceType: {
        default: 'default',
        type: String
      },
      elapsedTime: Number,
      preview: {
        default: false,
        type: Boolean
      },
      buildMode: {
        default: false,
        type: Boolean
      },
      embed: {
        default: false,
        type: Boolean
      },
      embedPreview: {
        default: false,
        type: Boolean
      },
      local: {
        default: false,
        type: Boolean
      },
      isLoading: {
        default: false,
        type: Boolean
      },
      hasError: {
        default: false,
        type: Boolean
      },
      isNew: {
        default: false,
        type: Boolean
      },
      embedOptions: Object,
      totalTime: Number,
      activeSince: Number,
      elements: Array,
      allowTemplateAdd: Boolean,
      boardMode: {
        default: 'default',
        type: String
      },
      performance: {
        default: 'high',
        type: String
      },
      collectors: Array,
      projects: Array,
      refID: String
    },
    setup() {
      const toast = useToast();
      return { toast };
    },
    data() {
      return {
        canvas: null,
        stageWidth: 0,
        stageHeight: 0,
        particlesKey: 0,
        titleKey: uuidv4(),
        sizeOptionsHours: [
          {text: '00', value: 0},
          {text: '01', value: 1},
          {text: '02', value: 2},
          {text: '03', value: 3},
          {text: '04', value: 4},
          {text: '05', value: 5},
          {text: '06', value: 6},
          {text: '07', value: 7},
          {text: '08', value: 8}
        ],
        sizeOptionsMinutes: [
          {text: '00', value: 0},
          {text: '15', value: 0.25},
          {text: '30', value: 0.5},
          {text: '45', value: 0.75}
        ],
        colors,
        particles
      }
    },
    computed: {
      isActive() {
        return !!this.activeSince;
      },
      colorLight() {
        let colorIndex = this.colors.findIndex(item => item.value === this.color );
        return this.colors[colorIndex].colors[100];
      },
      colorDark() {
        let colorIndex = this.colors.findIndex(item => item.value === this.color );
        return this.colors[colorIndex].colors[400];
      },
      colorGrad() {
        let colorIndex = this.colors.findIndex(item => item.value === this.color );
        return this.colors[colorIndex].colors[500];
      },
      colorDeep() {
        let colorIndex = this.colors.findIndex(item => item.value === this.color );
        return this.colors[colorIndex].colors[600];
      },
      colorOutline() {
        let colorIndex = this.colors.findIndex(item => item.value === this.color );
        return this.colors[colorIndex].colors[900];
      },
      playBallColor() {
        let activeBG = '';
        if (this.isActive) {
          //activeBG = ' bg-' + this.color + '-300 rounded-full';
          return 'hover:bg-transparent';
        }
        return 'text-' + this.color + '-900 rounded-full' + activeBG;
      },
      particlesConfig() {
        const colorIndex = this.colors.findIndex(item => item.value === this.color );
        const particleColors = Object.values(this.colors[colorIndex].colors);
        let particlesConfig = JSON.parse(JSON.stringify(this.particles));
        particlesConfig.particles.color.value = particleColors;
        return particlesConfig;
      },
      formattedElapsedTime() {
        const date = new Date(null);

        date.setSeconds(this.elapsedTime / 1000);
        const utc = date.toUTCString();
        return utc.substr(utc.indexOf(":") - 2, 8);
      },
      formattedRemainingTime() {
        const date = new Date(null);
        let overtime = false;
        let fullTime = ((this.size * 60) * 60);
        if (this.elapsedTime > 0) {
          fullTime = fullTime - (this.elapsedTime / 1000);
          // we are out of the time - overtime
          if (fullTime < 0) {
            fullTime = Math.abs(fullTime);
            overtime = true;
          }
        }
        date.setSeconds(fullTime);
        const utc = date.toUTCString();
        return {status: overtime, value: utc.substr(utc.indexOf(":") - 2, 8)};
      },
      styleObject() {
        // tw -100 and -500 colours
        let addOn = 90;
        let size = this.size / 1;
        if (size < 2) {
          size = 2;
        }

        if (this.boardMode === 'compact') {
          size = 2;
        }

        return {
          height: ((size * 100) + addOn) + "px"
        }
      },
      startStyleObject() {
        let perc = 2/100;
        let height = 2 - this.size;
        let topPercent = height / perc;
        return {
          top: topPercent + "%"
        }
      },
      remainingTimeStyleObject() {
        if (this.formattedRemainingTime.status) {
          return 'text-white bg-gray-900';
        } else {
          return 'text-' + this.color + '-800 bg-' + this.color + '-100';
        }
      },
      elementsProcessed () {
        //let initTime = null;

        let size = this.size / 1;
        let progressAdd = 0;

        if (this.size < 2) {
          let height = 2 - this.size;
          progressAdd = ((height * 60) * 60) * 1000;
          size = 2;
        }

        let heightTime = (((size * 60) * 60) * 1000 / 100); // one percent height
        let elementsNew = [];
        let totalHeight = progressAdd;

        // get the first start
        // get the block hours
        // calculate height and duration and plot it

        // get the highest start
        if (this.elements.length > 0) {
          // calculate durations and add positions
          this.elements.forEach((element) => {
            if (element.end) {
              totalHeight = totalHeight + (element.end - element.start);
              let bottomPercentage = totalHeight / heightTime;
              elementsNew.push({
                id: element.id,
                border: totalHeight,
                styleObject: {top: (bottomPercentage) + '%'}
              });
            }
          });
        }

        return elementsNew;
      },
      showControls() {
        if (this.embed === true && this.embedOptions.mode === 'view') {
          return false;
        } else {
          return true;
        }
      },
      projectObj() {
        if (this.projectRef && this.projectRef !== "null" && this.projects.length) {
          let project = this.projects.find(item => item.id === this.projectRef);
          return project;
        } else {
          return {};
        }
      }
    },
    methods: {
      start() {
        this.canvas.start();
        this.$emit('elementStart', this.id);
      },
      stop() {
        this.canvas.stop();
        this.$emit('elementEnd', this.id);
      },
      reset() {
        this.$emit('blockReset', this.id);
      },
      toggle() {
        if (this.preview) {
          this.toast.warning(`Please save your changes first to be able to edit the block.`);
        } else {
          if (this.embed === true && this.embedOptions.mode === 'view') return;
          if (this.embed === true && this.embedPreview === true) return;
          if (this.buildMode === true) return;
          if (this.isActive) {
            this.stop();
          } else {
            this.start();
          }
        }
      },
      blockEdit() {
        if (this.preview) {
          this.toast.warning(`Please save your changes first to be able to edit the block.`);
        } else {
          this.$emit('blockEditStart', this.id);
        }
      },
      deleteBlock() {
        if (this.preview) {
          this.toast.warning(`Please save your changes first to be able to edit the block.`);
        } else {
          this.$emit('blockDelete', this.id, this.refID);
        }
      },
      blockContinue() {
        this.$emit('handleTrackingChange', true);
      },
      forceRerenderParticles() {
        nextTick(() => {
          this.canvas.update(this.color);

        });
      },
      reload() {
        window.location.reload();
      }
    },
    watch: {
      boardMode() {
        this.forceRerenderParticles();
      },
      size() {
        this.forceRerenderParticles();
      },
      color() {
        this.forceRerenderParticles();
      },
      activeSince(val) {
        if (val) {
          this.canvas.start();
        } else {
          this.canvas.stop();
        }
      }
    },
    mounted () {
      if (this.isNew) {
        this.$emit('blockAdded', this.id);
        const block = document.querySelector("#block-" + this.id);
        //block.style.transform = "scale(0.5)";
        block.style.zIndex = 10000000;

        anime({
          targets: '#block-' + this.id,
          easing: 'linear',
          duration: 380,
          opacity: [1, 1],
          rotate: [3, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          delay: 600,
          //scale: [{value: 0.5},{value: 1}],
          matrix3d: [
            '0.1, 0, 0, 0, 0, 0.1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '0.318, 0, 0, 0, 0, 0.318, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '0.448, 0, 0, 0, 0, 0.448, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '0.688, 0, 0, 0, 0, 0.688, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '0.983, 0, 0, 0, 0, 0.983, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '1.011, 0, 0, 0, 0, 1.011, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '1.131, 0, 0, 0, 0, 1.131, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '1.147, 0, 0, 0, 0, 1.147, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '1.056, 0, 0, 0, 0, 1.056, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '1.129, 0, 0, 0, 0, 1.129, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '1.097, 0, 0, 0, 0, 1.097, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '1.048, 0, 0, 0, 0, 1.048, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '1.023, 0, 0, 0, 0, 1.023, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '0.996, 0, 0, 0, 0, 0.996, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '0.985, 0, 0, 0, 0, 0.985, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '0.965, 0, 0, 0, 0, 0.965, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '0.967, 0, 0, 0, 0, 0.967, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '0.979, 0, 0, 0, 0, 0.979, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '0.988, 0, 0, 0, 0, 0.988, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '0.996, 0, 0, 0, 0, 0.996, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '1.002, 0, 0, 0, 0, 1.002, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '1.002, 0, 0, 0, 0, 1.002, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '0.999, 0, 0, 0, 0, 0.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '0.999, 0, 0, 0, 0, 0.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1'
          ],
          complete: () => {
            anime.remove('#block-' + this.id);
            block.style.zIndex = 'auto';

            // Dirty fix to get around title rendering issue
            nextTick(() => {
              let titleUid = uuidv4();
              this.titleKey = titleUid;
            });
          }
        });
      }

      this.canvas = new Canvas(this.id, this.color, this.performance);

      if (this.activeSince) {
        this.canvas.start();
        this.blockContinue();
      }
    },
  }
</script>
