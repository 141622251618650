<template>
  <div
    :id="'block-' + id"
    :ref="'block-' + id"
    class="col-span-1 shadow-xl flex flex-col text-center bg-white rounded-xl shadow relative border-4 h-auto"
    :class="['bg-' + color + '-100 border-' + color + '-900', {'opacity-0': isNew}]"
  >
    <div v-if="hasError"
     class="absolute w-full h-full bg-red-200 opacity-90 z-50 text-red-900 font-medium pt-5"
    >
      Oh no, something went wrong!<br />Please <a @click="reload()" class="underline cursor-pointer">reload</a>.
    </div>

    <div class="absolute z-10 w-full h-full">
      <div class="rounded-md h-full opacity-20 border-3"
           style="border-radius: 8px;"
           :style="'box-shadow: inset 0 0 100px 0 #' + colorDeep + '; border-color: #' + colorLight">
      </div>
    </div>

    <div :class="'bg-' + color + '-200 border-' + color + '-300'"
      class="absolute w-full bottom-0 left-0"
      :style="progressStyle"
      style="transition: height 0.4s ease-in-out;"
    ></div>

    <div
      class="z-20 px-4 pt-4 relative shadow-none flex items-center"
      :class="'bg-transparent border-' + color + '-300'"
    >
      <div class="flex-shrink-0">
        <span class="rounded-lg inline-flex p-2"
          :class="'bg-' + color + '-50 border-' + color + '-300 border-2'">
          <BlockIcon
            @click="blockEdit"
            v-bind:type="faceType"
            v-bind:color="color"
            v-bind:isLoading="isLoading"
            v-bind:hasError="hasError"
          ></BlockIcon>
        </span>
      </div>
      <div class="flex-1">
        <svg
          @click="blockEdit"
          :key="titleKey"
          class="h-1 cursor-pointer z-10 text-gray-900 text-lg font-bold inline-block"
          style="font-family: 'Roboto Mono', monospace;width: 100%; height: 28px;">
          <text :style="'stroke: #' + colorOutline + ';'" text-anchor="middle" x="50%" y="24" fill="white" stroke-width="4" letter-spacing="2" paint-order="stroke">{{formattedTotal}}</text>
        </svg>

        <span @click="blockEdit" class="cursor-pointer font-bold" :class="'text-' + color + '-900'">{{title}}</span>
      </div>
      <div v-if="!showControls" class="flex-shrink-2 w-12">
      </div>
      <div v-if="showControls" class="flex-shrink-1">
        <div v-for="el in decreaseEl" v-bind:key="el.id" :id="el.id" :class="'text-' + color + '-800'" class="absolute top-1 font-bold text-lg" style="font-family: 'Roboto Mono', monospace;">
          {{el.value}}
        </div>
        <button
          @click="decrease"
          :class="['text-white border-2', textClass]"
          :disabled="!this.totalElements"
          style="padding: 1px; margin-bottom: 3px; margin-right: 5px;"
          class="block-decrease focus:outline-none focus:ring-none rounded-md border-b-4"
        >
          <svg class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>
        </button>
      </div>
      <div v-if="showControls" class="flex-shrink-1 items-center relative">
        <div v-for="el in increaseEl" v-bind:key="el.id" :id="el.id" :class="'text-' + color + '-900'" class="absolute -top-5 font-bold text-xl" style="font-family: 'Roboto Mono', monospace;">
          {{el.value}}
        </div>
        <button
          @click="increase('_default', '_default', faceType, increaseValue)"
          :class="'text-' + color + '-800 hover:bg-' + color + '-700 hover:text-' + color + '-200 border-2 border-b-4 border-' + color + '-800 bg-' + color + '-300'"
          class="block-increase focus:outline-none focus:ring-none rounded-md">
          <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd"></path></svg>
        </button>
      </div>

      <button v-if="buildMode" @click="blockEdit"
        class="z-20 top-1 left-1 absolute opacity-50 hover:opacity-100 rounded-md inline-flex text-gray-900 hover:text-gray-1000 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
        :class="'text-' + color + '-900'"
      >
        <svg class="w-4 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"></path><path fill-rule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clip-rule="evenodd"></path></svg>
      </button>

      <button v-if="buildMode"
        class="cursor-move block-drag-handle z-20 top-3 left-1/2 transform -translate-x-1/2 -translate-y-1/2 absolute opacity-50 hover:opacity-100 rounded-md inline-flex text-gray-900 hover:text-gray-1000 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
        :class="'text-' + color + '-900'"
      >
        <svg class="w-6 h-6" stroke="currentColor" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"/><circle cx="5" cy="9" r="1" /><circle cx="5" cy="15" r="1" /><circle cx="12" cy="9" r="1" /><circle cx="12" cy="15" r="1" /><circle cx="19" cy="9" r="1" /><circle cx="19" cy="15" r="1" />
        </svg>
      </button>

      <button v-if="embed && local" @click="reset"
        class="z-20 top-1 right-1 absolute opacity-50 hover:opacity-100 rounded-md inline-flex text-gray-900 hover:text-gray-1000 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
        :class="'text-' + color + '-700 hover:text-' + color + '-900'"
      >
        <span class="sr-only">Reset</span>
        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd" d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z" clip-rule="evenodd" />
        </svg>
      </button>

      <button v-if="buildMode" @click="deleteBlock"
        class="z-20 top-1 right-1 absolute opacity-50 hover:opacity-100 rounded-md inline-flex text-gray-900 hover:text-gray-1000 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
        :class="'text-' + color + '-900'"
      >
        <span class="sr-only">Close</span>
        <!-- Heroicon name: x -->
        <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
          <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
        </svg>
      </button>
    </div>
    <div class="z-20 pb-2">
      <span class="text-xs block leading-none" :class="'text-' + color + '-500'">{{lastElement}}</span>
    </div>

    <div v-if="counters && counters.length" class="flex z-20 items-center justify-center flex-wrap">
      <div
        v-for="counter in countersProcessed"
        class="px-1 pb-2 flex cursor-pointer"
        :class="'border-' + color + '-300'"
        v-bind:key="counter"
        @click="increase(counter.id, counter.title, counter.icon, counter.increaseValue)"
        :id="'counter-' + counter.id"
      >
        <div
          class="px-2 pb-2 relative flex border-2 border-b-3 items-center p-2 pb-3 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 origin-bottom"
          :style="'box-shadow: inset 0px -6px 0px #' + colorDark + ', inset 0 -7px 0 0 #' + colorLight + ', inset 0px 1px 0px 1px #' + colorLight + ';'"
          :class="'border-' + color + '-600 bg-' + color + '-200 hover:bg-' + color + '-100 focus:ring-' + color + '-50 '"
        >
          <div class="w-6 h-6">
            <BlockIcon
              v-bind:type="counter.icon"
              v-bind:color="color"
              v-bind:is-preview=true
            ></BlockIcon>
          </div>
          <span
            class="inline-flex items-center px-1 py-0.5 rounded-full text-xs absolute right-0 top-0 opacity-80"
            :class="'bg-' + color + '-100 text-' + color + '-800'"
            style="font-size: 9px;"
          >
            {{counter.total}}
          </span>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
  import {colors} from '../colors/colors.js';
  import BlockIcon from './BlockIcon.vue';
  import {useToast} from "vue-toastification";
  import {v4 as uuidv4} from "uuid";
  import {nextTick} from "vue";
  import dayjs from "dayjs";
  import relativeTime from "dayjs/plugin/relativeTime";
  import anime from "animejs";

  dayjs.extend(relativeTime);

  export default {
    name: 'BlockCounter',
    components: {
      BlockIcon
    },
    props: {
      id: String,
      title: String,
      color: String,
      size: Number,
      src: String,
      faceType: {
        default: 'default',
        type: String
      },
      counters: Array,
      elapsedTime: Number,
      preview: {
        default: false,
        type: Boolean
      },
      buildMode: {
        default: false,
        type: Boolean
      },
      embed: {
        default: false,
        type: Boolean
      },
      embedPreview: {
        default: false,
        type: Boolean
      },
      local: {
        default: false,
        type: Boolean
      },
      isLoading: {
        default: false,
        type: Boolean
      },
      hasError: {
        default: false,
        type: Boolean
      },
      isNew: {
        default: false,
        type: Boolean
      },
      embedOptions: Object,
      totalTime: Number,
      activeSince: Number,
      elements: Array,
      allowTemplateAdd: Boolean,
      refID: String,
      increaseValue: Number,
      blockTick: Number
    },
    setup() {
      const toast = useToast();
      return { toast };
    },
    data() {
      return {
        elHeight: 0,
        elWidth: 0,
        increaseEl: [],
        decreaseEl: [],
        titleKey: uuidv4(),
        sizeOptionsHours: [
          {text: '00', value: 0},
          {text: '01', value: 1},
          {text: '02', value: 2},
          {text: '03', value: 3},
          {text: '04', value: 4},
          {text: '05', value: 5},
          {text: '06', value: 6},
          {text: '07', value: 7},
          {text: '08', value: 8}
        ],
        sizeOptionsMinutes: [
          {text: '00', value: 0},
          {text: '15', value: 0.25},
          {text: '30', value: 0.5},
          {text: '45', value: 0.75}
        ],
        colors
      }
    },
    computed: {
      isActive() {
        return !!this.activeSince;
      },
      colorLight() {
        let colorIndex = this.colors.findIndex(item => item.value === this.color );
        return this.colors[colorIndex].colors[100];
      },
      colorDark() {
        let colorIndex = this.colors.findIndex(item => item.value === this.color );
        return this.colors[colorIndex].colors[500];
      },
      colorDeep() {
        let colorIndex = this.colors.findIndex(item => item.value === this.color );
        return this.colors[colorIndex].colors[600];
      },
      colorGrad() {
        let colorIndex = this.colors.findIndex(item => item.value === this.color );
        return this.colors[colorIndex].colors[600];
      },
      colorOutline() {
        let colorIndex = this.colors.findIndex(item => item.value === this.color );
        return this.colors[colorIndex].colors[900];
      },
      totalElements() {
        let total = 0;
        this.elements.forEach((element) => {
          total = total + element.value;
        });
        total = Math.round((total + Number.EPSILON) * 100) / 100;
        return total;
      },
      formattedTotal() {
        return this.totalElements + "/" + this.size;
      },
      progressStyle() {
        let height = this.elHeight - 8;
        let width = this.elWidth;
        let size = this.size;
        let total = this.totalElements;

        let progress_perc = total / size;
        let bar_perc = progress_perc * height;

        if (bar_perc >= height) {
          bar_perc = height;
        }

        let borderTopWidth = '0';
        if (bar_perc) {
          borderTopWidth = '2px';
        }

        return {
          height: bar_perc + 'px',
          width: (width - 8) + 'px',
          left: '4px',
          bottom: '4px',
          borderTopWidth: borderTopWidth
        }
      },
      textClass() {
        if (this.totalElements) {
          return 'text-' + this.color + '-800 bg-' + this.color + '-300 hover:text-' + this.color + '-200 hover:bg-' + this.color + '-700 border-' + this.color + '-800';
        } else {
          return 'text-' + this.color + '-500 bg-' + this.color + '-200 border-' + this.color + '-300 cursor-not-allowed';
        }
      },
      lastElement() {
        if (this.elements.length) {
          let lastEl = this.elements[this.elements.length - 1];
          return this.lastElementTitle + dayjs(lastEl.start).from(this.blockTick);
        }
        return '- -';
      },
      showControls() {
        if (this.embed === true && this.embedOptions.mode === 'view') {
            return false;
        } else {
          return true;
        }
      },
      lastElementTitle() {
        const lastItem = this.elements[this.elements.length - 1];
        if (typeof lastItem.title !== 'undefined' && lastItem.title !== '_default' && lastItem.title !== '') {
          return lastItem.title + ' ';
        }
        return '';
      },
      countersProcessed() {
        let countersNew = [];
        let elementsCount = [];
        if (this.counters.length > 0) {
          // get all elements and calculate them
          this.elements.forEach((element) => {
            if (element.icon && element.value) {
              let key = element.icon + '_' + element.value;
              if (elementsCount[key]) {
                elementsCount[key] = elementsCount[key] + 1;
              } else {
                elementsCount[key] = 1;
              }
            }
          });

          // calculate number of items per counter
          this.counters.forEach((counter) => {
            let key = counter.icon + '_' + counter.increaseValue;
            countersNew.push({
              id: counter.id,
              increaseValue: counter.increaseValue,
              title: counter.title,
              icon: counter.icon,
              total: elementsCount[key] ? elementsCount[key] : 0
            });
          });
        }
        return countersNew;
      }
    },
    methods: {
      increase(incId, incTitle, incIcon, incValue) {
        //this.animateButton(incId);
        incValue = incValue / 1;
        if (this.preview) {
          this.toast.warning(`Please save your changes first to be able to edit the block.`);
        } else if (this.buildMode === true) {
          return;
        } else {
          if (this.embed === true && this.embedPreview === true) return;
          let uid = uuidv4();
          this.increaseEl.push({value: '+' + incValue, id: 'in-el-' + uid});

          nextTick(() => {
            anime({
              targets: '#block-' + this.id + ' .block-increase',
              scale: [{value: 0.6},{value: 1}],
              easing: 'easeOutSine',
              duration: 100,
              complete: () => {
                anime.remove('#block-' + this.id + ' .block-increase');
              }
            });
            anime({
              targets: '#in-el-' + uid,
              translateY: [{value: -8}, {value: 10}],
              translateX: [{value: -150}],
              opacity: [{value: 0}],
              scale: [{value: 3}],
              easing: 'easeInOutSine',
              duration: 600,
              complete: () => {
                let elIndex = this.increaseEl.findIndex(item => item.id === 'in-el-' + uid );
                this.increaseEl.splice(elIndex, 1);
                anime.remove('#in-el-' + uid);
              }
            });
          });

          this.$emit('elementIncrease', this.id, incTitle, incIcon, incValue);
        }
      },
      decrease() {
        if (this.preview) {
          this.toast.warning(`Please save your changes first to be able to edit the block.`);
        } else {
          if (this.embed === true && this.embedPreview === true) return;
          let uid = uuidv4();
          this.decreaseEl.push({value: '-' + this.increaseValue, id: 'in-el-' + uid});

          nextTick(() => {
            anime({
              targets: '#block-' + this.id + ' .block-decrease',
              scale: [{value: 0.4},{value: 1}],
              easing: 'easeOutSine',
              duration: 100,
              complete: () => {
                anime.remove('#block-' + this.id + ' .block-increase');
              }
            });
            anime(
                {
                  targets: '#in-el-' + uid,
                  translateY: [{value: -12}, {value: 10}],
                  translateX: [-130, 0],
                  opacity: [{value: 0}],
                  scale: [{value: 0.8}],
                  easing: 'easeInOutSine',
                  duration: 600,
                  complete: () => {
                    let elIndex = this.decreaseEl.findIndex(item => item.id === 'in-el-' + uid );
                    this.decreaseEl.splice(elIndex, 1);
                    anime.remove('#in-el-' + uid);
                  }
                });
          });

          this.$emit('elementDecrease', this.id);
        }
      },
      reset() {
        this.$emit('blockReset', this.id);
      },
      blockEdit() {
        if (this.preview) {
          this.toast.warning(`Please save your changes first to be able to edit the block.`);
        } else {
          this.$emit('blockEditStart', this.id);
        }
      },
      deleteBlock() {
        if (this.preview) {
          this.toast.warning(`Please save your changes first to be able to edit the block.`);
        } else {
          this.$emit('blockDelete', this.id, this.refID);
        }
      },
      reload() {
        window.location.reload();
      },
      animateButton(id) {
        nextTick(() => {
          anime({
            targets: '#counter-' + id,
            easing: 'easeOutQuad',
            duration: 175,
            scale: [{value: 0.9},{value: 1}],
            //paddingBottom: [30],
            //marginTop: [20, 0],
            backgroundColor: ['#6EE7B7', '#bbf7d0'],
            borderBottom: [1, 3],
            boxShadow: ['inset 0px 0px 0px #34D399, inset 0 0px 0 0 #ECFDF5, inset 0px 1px 0px 1px #ECFDF5', 'inset 0px -6px 0px #34D399, inset 0 -7px 0 0 #ECFDF5, inset 0px 1px 0px 1px #ECFDF5'],
            complete: () => {
              anime.remove('#counter-' + id);
            }
          });
          anime({
            targets: '#counter-' + id + ' svg',
            easing: 'easeOutQuad',
            duration: 175,
            //scale: [{value: 0.95},{value: 1}],
            paddingBottom: [0, 0],
            paddingTop: [4, 0],
            complete: () => {
              anime.remove('#counter-' + id + ' svg');
            }
          });
        });
      },
    },
    mounted() {
      if (this.isNew) {
        this.$emit('blockAdded', this.id);
        const block = document.querySelector("#block-" + this.id);
        //block.style.transform = "scale(0.5)";
        block.style.zIndex = 10000000;

        anime({
          targets: '#block-' + this.id,
          easing: 'linear',
          duration: 380,
          opacity: [1, 1],
          rotate: [3, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          delay: 600,
          //scale: [{value: 0.5},{value: 1}],
          matrix3d: [
            '0.1, 0, 0, 0, 0, 0.1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '0.318, 0, 0, 0, 0, 0.318, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '0.448, 0, 0, 0, 0, 0.448, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '0.688, 0, 0, 0, 0, 0.688, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '0.983, 0, 0, 0, 0, 0.983, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '1.011, 0, 0, 0, 0, 1.011, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '1.131, 0, 0, 0, 0, 1.131, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '1.147, 0, 0, 0, 0, 1.147, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '1.056, 0, 0, 0, 0, 1.056, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '1.129, 0, 0, 0, 0, 1.129, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '1.097, 0, 0, 0, 0, 1.097, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '1.048, 0, 0, 0, 0, 1.048, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '1.023, 0, 0, 0, 0, 1.023, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '0.996, 0, 0, 0, 0, 0.996, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '0.985, 0, 0, 0, 0, 0.985, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '0.965, 0, 0, 0, 0, 0.965, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '0.967, 0, 0, 0, 0, 0.967, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '0.979, 0, 0, 0, 0, 0.979, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '0.988, 0, 0, 0, 0, 0.988, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '0.996, 0, 0, 0, 0, 0.996, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '1.002, 0, 0, 0, 0, 1.002, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '1.002, 0, 0, 0, 0, 1.002, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '0.999, 0, 0, 0, 0, 0.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '0.999, 0, 0, 0, 0, 0.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1'
          ],
          complete: () => {
            anime.remove('#block-' + this.id);
            block.style.zIndex = 'auto';

            // Dirty fix to get around title rendering issue
            nextTick(() => {
              let titleUid = uuidv4();
              this.titleKey = titleUid;
            });
          }
        });
      }

      nextTick(() => {
        if (this.$refs['block-' + this.id] !== null) {
          this.elHeight = this.$refs['block-' + this.id].clientHeight;
          this.elWidth = this.$refs['block-' + this.id].clientWidth;
        }
      });
    }
  }
</script>
