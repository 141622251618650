<template>
  <div
    class="col-span-1 shadow-xl hover:opacity-100 flex flex-col text-center bg-white rounded-xl overflow-hidden relative border-4"
    :class="['block-component bg-' + color + '-100', 'border-' + color + '-900', {'opacity-0': isNew}]"
    :id="'block-' + id"
  >

    <div class="absolute z-10 w-full h-full">
      <div class="rounded-md h-full opacity-20 border-3"
           style="border-radius: 8px;"
           :style="'box-shadow: inset 0 0 100px 0 #' + colorDeep + '; border-color: #' + colorLight">
      </div>
    </div>

    <div
      class="z-20 p-4 relative shadow-none"
      :class="'bg-transparent border-' + color + '-300'"
    >
      <svg
        @click="blockEdit"
        :key="titleKey"
        class="h-1 cursor-pointer z-10 text-gray-900 text-lg font-bold inline-block"
        style="font-family: 'Roboto Mono', monospace;width: 100%; height: 32px;">
        <text :style="'stroke: #' + colorOutline + ';'" text-anchor="middle" x="50%" y="26" fill="white" stroke-width="4" letter-spacing="2" paint-order="stroke">{{formattedElapsedTime}}</text>
      </svg>

      <span
        @click="blockEdit"
        style=""
        class="cursor-pointer z-10 text-md font-bold inline-block"
        :class="'text-' + color + '-900'"
      >
        {{title}}
      </span>

      <button v-if="buildMode" @click="blockEdit"
        class="z-20 top-1 left-1 absolute opacity-50 hover:opacity-100 rounded-md inline-flex text-gray-900 hover:text-gray-1000 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
        :class="'text-' + color + '-700 hover:text-' + color + '-900'"
      >
        <svg class="w-4 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"></path><path fill-rule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clip-rule="evenodd"></path></svg>
      </button>

      <button v-if="buildMode"
        class="cursor-move block-drag-handle z-20 top-3 left-1/2 transform -translate-x-1/2 -translate-y-1/2 absolute opacity-50 hover:opacity-100 rounded-md inline-flex text-gray-900 hover:text-gray-1000 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
        :class="'text-' + color + '-700 hover:text-' + color + '-900'"
      >
        <svg class="w-6 h-6" stroke="currentColor" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"/><circle cx="5" cy="9" r="1" /><circle cx="5" cy="15" r="1" /><circle cx="12" cy="9" r="1" /><circle cx="12" cy="15" r="1" /><circle cx="19" cy="9" r="1" /><circle cx="19" cy="15" r="1" />
        </svg>
      </button>

      <button v-if="buildMode" @click="deleteBlock"
        class="z-20 top-1 right-1 absolute opacity-50 hover:opacity-100 rounded-md inline-flex text-gray-900 hover:text-gray-1000 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
        :class="'text-' + color + '-700 hover:text-' + color + '-900'"
      >
        <span class="sr-only">Close</span>
        <!-- Heroicon name: x -->
        <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
          <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
        </svg>
      </button>
    </div>

    <div v-if="progressEnd"
      class="w-full border-t p-4"
      :class="'border-' + color + '-500'"
    >
      <div class="">
        <span class="leading-4 inline-flex p-2 block text-3xl"
              style="font-family: 'Roboto Mono', monospace;"
              :class="'text-' + color + '-700'">
          {{formattedProgressEnd.percentage}}%
        </span>
      </div>
      <div class="">
        <span style="font-family: 'Roboto Mono', monospace;">{{formattedProgressEnd.progress}}</span>
      </div>
    </div>

    <div
      v-for="counter in countersProcessed"
      class="w-full border-t-2 p-4 flex leading-4"
      :class="'border-' + color + '-300'"
      v-bind:key="counter"
    >
      <div class="flex-shrink-0">
        <span class="rounded-lg inline-flex p-2"
              :class="'bg-' + color + '-50 border-' + color + '-300 border-2'">
          <BlockIcon
            v-bind:type="counter.icon"
            v-bind:color="color"
          ></BlockIcon>
        </span>
      </div>
      <div class="flex-grow text-left pl-4">
        <span class="text-lg" style="font-family: 'Roboto Mono', monospace;">{{counter.value}}</span><br />
        <span class="text-sm">{{counter.title}}</span>
      </div>
    </div>

  </div>

</template>
<script>
  import {colors} from '../colors/colors.js';
  import { useToast } from "vue-toastification";
  import {v4 as uuidv4} from "uuid";
  import {nextTick} from "vue";
  import dayjs from "dayjs";
  import relativeTime from "dayjs/plugin/relativeTime";
  import BlockIcon from "./BlockIcon";
  import anime from "animejs";

  dayjs.extend(relativeTime);

  export default {
    name: 'Block',
    components: {
      BlockIcon
    },
    props: {
      id: String,
      title: String,
      color: String,
      size: Number,
      src: String,
      progressStart: Number,
      progressEnd: Number,
      counters: Array,
      faceType: {
        default: 'default',
        type: String
      },
      elapsedTime: Number,
      preview: {
        default: false,
        type: Boolean
      },
      buildMode: {
        default: false,
        type: Boolean
      },
      embed: {
        default: false,
        type: Boolean
      },
      isLoading: {
        default: false,
        type: Boolean
      },
      hasError: {
        default: false,
        type: Boolean
      },
      isNew: {
        default: false,
        type: Boolean
      },
      embedOptions: Object,
      totalTime: Number,
      activeSince: Number,
      elements: Array,
      allowTemplateAdd: Boolean,
      refID: String,
      blockTick: Number
    },
    setup() {
      const toast = useToast();
      return { toast };
    },
    data() {
      return {
        titleKey: uuidv4(),
        colors
      }
    },
    computed: {
      colorLight() {
        let colorIndex = this.colors.findIndex(item => item.value === this.color );
        return this.colors[colorIndex].colors[100];
      },
      colorDark() {
        let colorIndex = this.colors.findIndex(item => item.value === this.color );
        return this.colors[colorIndex].colors[400];
      },
      colorDeep() {
        let colorIndex = this.colors.findIndex(item => item.value === this.color );
        return this.colors[colorIndex].colors[600];
      },
      colorOutline() {
        let colorIndex = this.colors.findIndex(item => item.value === this.color );
        return this.colors[colorIndex].colors[900];
      },
      formattedElapsedTime() {
        const since = this.progressStart;
        const date1 = dayjs(since);
        const date2 = dayjs(this.blockTick);

        const display = [];

        // calculate times, we are using dayjs because of the leap years, etc...
        let years = date2.diff(date1, 'years');
        if (years) display.push(years + 'y');
        const yearsDate = dayjs(since).add(years, 'years');
        let days = date2.diff(yearsDate, 'days');
        if (years || days) display.push(days + 'd');
        const hoursDate = dayjs(since).add(years, 'years').add(days, 'days');
        let hours = date2.diff(hoursDate, 'hours');
        if (years || days || hours) display.push(hours + 'h');
        const minutesDate = dayjs(since).add(years, 'years').add(days, 'days').add(hours, 'hours');
        let minutes = date2.diff(minutesDate, 'minutes');
        display.push(minutes + 'm');

        return display.join(" ");
      },
      formattedProgressEnd() {
        const since = this.progressStart;
        let end = this.blockTick; // so that this updates automatically
        end = this.progressEnd;
        const date1 = dayjs(since);
        const date2 = dayjs(end);
        const now = this.blockTick;

        // calculate percentage of progress
        const full = date2.valueOf() - date1.valueOf();
        const full_now = now - date1.valueOf();
        const progressPercentage = ~~((full_now / full) * 100);

        const display = [];

        // calculate times, we are using dayjs because of the leap years, etc...
        let years = date2.diff(date1, 'years');
        if (years) display.push(years + 'y');
        const yearsDate = dayjs(since).add(years, 'years');
        let days = date2.diff(yearsDate, 'days');
        if (years || days) display.push(days + 'd');
        const hoursDate = dayjs(since).add(years, 'years').add(days, 'days');
        let hours = date2.diff(hoursDate, 'hours');
        if (years || days || hours) display.push(hours + 'h');
        const minutesDate = dayjs(since).add(years, 'years').add(days, 'days').add(hours, 'hours');
        let minutes = date2.diff(minutesDate, 'minutes');
        display.push(minutes + 'm');

        return {
          progress: display.join(" "),
          percentage: progressPercentage
        }
      },
      countersProcessed() {
        let countersNew = [];
        const since = this.progressStart; //1566320435000;
        const date1 = dayjs(since);
        const date2 = dayjs(this.blockTick);

        const days = date2.diff(date1, 'day', true);

        if (this.counters.length > 0) {
          // calculate durations and add positions
          this.counters.forEach((counter) => {
            let value = 0;
            if (counter.type === 'number') {
              value = counter.day * days;
              value = Math.round((value + Number.EPSILON) * 100) / 100;
            } else if (counter.type === 'date') {
              // counter day value IS in seconds
              let value_m = counter.day * days;
              const display = [];

              let value_days = ~~(value_m / (24 * 3600));
              if (value_days) display.push(value_days + 'd');

              let value_hours = ~~((value_m - (value_days * 86400)) / 3600);
              if (value_hours) display.push(value_hours + 'h');
              let value_minutes = ~~((value_m - (value_days * 86400) - (value_hours * 3600)) / 60);
              if (value_minutes) display.push(value_minutes + 'm');
              let value_seconds = ~~(value_m - (value_days * 86400) - (value_hours * 3600) - (value_minutes * 60));
              display.push(value_seconds + 's');
              value = display.join(" ");
            }
            countersNew.push({
              value: value,
              title: counter.title,
              icon: counter.icon
            });
          });
        }
        return countersNew;
      }
    },
    methods: {
      reset() {
        this.$emit('blockReset', this.id);
      },
      blockEdit() {
        if (this.preview) {
          this.toast.warning(`Please save your changes first to be able to edit the block.`);
        } else {
          this.$emit('blockEditStart', this.id);
        }
      },
      deleteBlock() {
        if (this.preview) {
          this.toast.warning(`Please save your changes first to be able to edit the block.`);
        } else {
          this.$emit('blockDelete', this.id, this.refID);
        }
      },
    },
    mounted() {
      if (this.isNew) {
        this.$emit('blockAdded', this.id);
        const block = document.querySelector("#block-" + this.id);
        //block.style.transform = "scale(0.5)";
        block.style.zIndex = 10000000;

        anime({
          targets: '#block-' + this.id,
          easing: 'linear',
          duration: 380,
          opacity: [1, 1],
          rotate: [3, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          delay: 600,
          //scale: [{value: 0.5},{value: 1}],
          matrix3d: [
            '0.1, 0, 0, 0, 0, 0.1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '0.318, 0, 0, 0, 0, 0.318, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '0.448, 0, 0, 0, 0, 0.448, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '0.688, 0, 0, 0, 0, 0.688, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '0.983, 0, 0, 0, 0, 0.983, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '1.011, 0, 0, 0, 0, 1.011, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '1.131, 0, 0, 0, 0, 1.131, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '1.147, 0, 0, 0, 0, 1.147, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '1.056, 0, 0, 0, 0, 1.056, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '1.129, 0, 0, 0, 0, 1.129, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '1.097, 0, 0, 0, 0, 1.097, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '1.048, 0, 0, 0, 0, 1.048, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '1.023, 0, 0, 0, 0, 1.023, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '0.996, 0, 0, 0, 0, 0.996, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '0.985, 0, 0, 0, 0, 0.985, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '0.965, 0, 0, 0, 0, 0.965, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '0.967, 0, 0, 0, 0, 0.967, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '0.979, 0, 0, 0, 0, 0.979, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '0.988, 0, 0, 0, 0, 0.988, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '0.996, 0, 0, 0, 0, 0.996, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '1.002, 0, 0, 0, 0, 1.002, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '1.002, 0, 0, 0, 0, 1.002, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '0.999, 0, 0, 0, 0, 0.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '0.999, 0, 0, 0, 0, 0.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1'
          ],
          complete: () => {
            anime.remove('#block-' + this.id);
            block.style.zIndex = 'auto';

            // Dirty fix to get around title rendering issue
            nextTick(() => {
              let titleUid = uuidv4();
              this.titleKey = titleUid;
            });
          }
        });
      }
    }
  }
</script>
