<template>
  <div :id="'block-face-' + id">
    <template v-if="isActiveFace">
      <svg
        v-if="type==='hearts'"
        version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 250 250" style="enable-background:new 0 0 250 250;" xml:space="preserve"
        :class="{
          'ballBounce cursor-pointer block lg:block w-32 h-32': !isPreview,
          'cursor-pointer block lg:block': isPreview
        }"
      >
        <g>
          <circle :style="'fill:#' + ballColor[400]" cx="125" cy="125.8" r="119.5"/>
        </g>
        <g>
          <ellipse :style="'fill:#' + ballColor[300]" cx="125" cy="108.3" rx="114" ry="102"/>
        </g>
        <g>
          <path :style="'fill:#' + ballColor[900]" class="ballStroke" d="M125,10c30.7,0,59.6,12,81.3,33.7S240,94.3,240,125s-12,59.6-33.7,81.3S155.7,240,125,240s-59.6-12-81.3-33.7
		S10,155.7,10,125s12-59.6,33.7-81.3S94.3,10,125,10 M125,0C56,0,0,56,0,125s56,125,125,125s125-56,125-125S194,0,125,0L125,0z"/>
        </g>
        <g name="reflection" class="faceLightRotate">
          <path style="opacity:0.25;fill:none;stroke:#FFFFFF;stroke-width:14;stroke-linecap:round;stroke-miterlimit:10;" d="M209.4,192.4c2.2-2.8,4.3-5.7,6.3-8.7"/>
          <path style="opacity:0.25;fill:none;stroke:#FFFFFF;stroke-width:14;stroke-linecap:round;stroke-miterlimit:10;" d="M159.2,226.7c3.2-1,6.4-2.2,9.5-3.5c3.1-1.3,6.1-2.8,9.1-4.4c3-1.6,5.9-3.4,8.7-5.3c2.8-1.9,5.6-4,8.2-6.2"/>
          <path style="opacity:0.5;fill:none;stroke:#FFFFFF;stroke-width:14;stroke-linecap:round;stroke-miterlimit:10;" d="M49,48.5c19-19,45.3-30.8,74.2-30.8"/>
        </g>
        <g class="blockActive">
          <g class="">
            <path style="fill:none;stroke-width:9.8995;stroke-linecap:round;stroke-miterlimit:10;" d="M100.5,162.3c0,0,24.5,26,49,0"
                  :style="'stroke:#' + ballColor[900]"
            />
            <g class="faceEyesSmirk">

              <g>
                <g>
                  <path style="fill:#DC2626;" d="M64.5,155.8c-0.6,0-1.2-0.1-1.7-0.4c-0.3-0.2-8.5-4-16.6-11.2C34.9,134.5,29,123.4,29,112c0-11,9-20,20-20
                    c6.5,0,12.3,3.2,16,8c3.7-4.9,9.5-8,16-8c11,0,20,9,20,20c0,11.4-6.1,22.6-17.7,32.3c-8.5,7.1-16.8,11-17.2,11.1
                    C65.6,155.7,65,155.8,64.5,155.8z"/>
                  <path style="fill:#F87171;" d="M81,96c8.8,0,16,7.2,16,16c0,24.9-32.5,39.8-32.5,39.8S33,136.9,33,112c0-8.8,7.2-16,16-16s16,7.2,16,16
                    C65,103.2,72.2,96,81,96 M81,88c-6.1,0-11.8,2.3-16,6.1c-4.2-3.8-9.9-6.1-16-6.1c-13.2,0-24,10.8-24,24c0,29.7,34.6,46.3,36,47
                    c1.1,0.5,2.3,0.8,3.4,0.8c1.1,0,2.3-0.2,3.3-0.7c1.5-0.7,37.2-17.4,37.2-47.1C105,98.8,94.2,88,81,88L81,88z"/>
                </g>
                <path style="fill:none;stroke:#EF4444;stroke-width:8;stroke-linecap:round;stroke-miterlimit:10;" d="M69,139.9c0,0,7.9-5,15.2-13.2"/>
                <ellipse transform="matrix(0.9232 -0.3844 0.3844 0.9232 -37.5178 26.389)" style="fill:#FFFFFF;" cx="47.3" cy="107" rx="10.1" ry="6.3"/>
              </g>
                            <g>
                <g>
                  <path style="fill:#DC2626;" d="M184.5,155.8c-0.6,0-1.2-0.1-1.7-0.4c-0.3-0.2-8.5-4-16.6-11.2c-11.2-9.7-17.1-20.9-17.1-32.3
                    c0-11,9-20,20-20c6.5,0,12.3,3.2,16,8c3.7-4.9,9.5-8,16-8c11,0,20,9,20,20c0,11.4-6.1,22.6-17.7,32.3c-8.5,7.1-16.8,11-17.2,11.1
                    C185.6,155.7,185,155.8,184.5,155.8z"/>
                  <path style="fill:#F87171;" d="M201,96c8.8,0,16,7.2,16,16c0,24.9-32.5,39.8-32.5,39.8S153,136.9,153,112c0-8.8,7.2-16,16-16s16,7.2,16,16
                    C185,103.2,192.2,96,201,96 M201,88c-6.1,0-11.8,2.3-16,6.1c-4.2-3.8-9.9-6.1-16-6.1c-13.2,0-24,10.8-24,24
                    c0,29.7,34.6,46.3,36,47c1.1,0.5,2.3,0.8,3.4,0.8c1.1,0,2.3-0.2,3.3-0.7c1.5-0.7,37.2-17.4,37.2-47.1C225,98.8,214.2,88,201,88
                    L201,88z"/>
                </g>
                              <path style="fill:none;stroke:#EF4444;stroke-width:8;stroke-linecap:round;stroke-miterlimit:10;" d="M189,139.9c0,0,7.9-5,15.2-13.2"/>

                              <ellipse transform="matrix(0.9232 -0.3844 0.3844 0.9232 -28.2971 72.5188)" style="fill:#FFFFFF;" cx="167.3" cy="107" rx="10.1" ry="6.3"/>
              </g>

            </g>
          </g>
        </g>
      </svg>
      <svg
        v-else-if="type==='argh'"
        version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 250 250" style="enable-background:new 0 0 250 250;" xml:space="preserve"
        :class="{
          'ballBounce cursor-pointer block lg:block w-32 h-32': !isPreview,
          'cursor-pointer block lg:block': isPreview
        }"
      >
        <g>
          <circle :style="'fill:#' + ballColor[400]" cx="125" cy="125.8" r="119.5"/>
        </g>
                <g>
          <ellipse :style="'fill:#' + ballColor[300]" cx="125" cy="108.3" rx="114" ry="102"/>
        </g>
                <g>
          <path :style="'fill:#' + ballColor[900]" d="M125,10c30.7,0,59.6,12,81.3,33.7S240,94.3,240,125s-12,59.6-33.7,81.3S155.7,240,125,240s-59.6-12-81.3-33.7
            S10,155.7,10,125s12-59.6,33.7-81.3S94.3,10,125,10 M125,0C56,0,0,56,0,125s56,125,125,125s125-56,125-125S194,0,125,0L125,0z"/>
        </g>
                <path style="opacity:0.75;fill:none;stroke:#FFFFFF;stroke-width:14;stroke-linecap:round;stroke-miterlimit:10;" d="M49,48.5c19-19,45.3-30.8,74.2-30.8"/>
                <path style="opacity:0.5;fill:none;stroke:#FFFFFF;stroke-width:14;stroke-linecap:round;stroke-miterlimit:10;" d="M209.4,192.4c2.2-2.8,4.3-5.7,6.3-8.7"/>
                <path style="opacity:0.5;fill:none;stroke:#FFFFFF;stroke-width:14;stroke-linecap:round;stroke-miterlimit:10;" d="M159.2,226.7c3.2-1,6.4-2.2,9.5-3.5c3.1-1.3,6.1-2.8,9.1-4.4c3-1.6,5.9-3.4,8.7-5.3c2.8-1.9,5.6-4,8.2-6.2"/>
                <path :style="'stroke:#' + ballColor[900]" style="fill:none;stroke-width:9.8995;stroke-linecap:round;stroke-miterlimit:10;" d="M100.5,173.8c0,0,24.5-26,49,0"/>
                <g>
          <path :style="'fill:#' + ballColor[900]" d="M74,108c-6.4-3.2-12-7.1-16.4-11.2C46.8,100.5,39,112,39,125.6c0,16.6,11.6,30.1,26,30.1s26-13.5,26-30.1
            c0-4.3-0.8-8.4-2.2-12.1C84,112.3,79,110.5,74,108z"/>
                  <path style="fill:#FFFFFF;" d="M67.3,113.1c-2.9-1.8-5.7-3.7-8.2-5.7c-0.8,0.3-1.6,0.9-2.1,1.6c-2.4,3.2-0.3,9,4.8,12.8
            c5.1,3.9,11.2,4.4,13.7,1.2c1.1-1.4,1.2-3.3,0.7-5.2C73.2,116.4,70.2,114.9,67.3,113.1z"/>
                  <path :style="'stroke:#' + ballColor[700]" style="fill:none;stroke-width:8;stroke-linecap:round;stroke-miterlimit:10;" d="M56,141.8c0,0,7.9,8.3,17,0"/>
        </g>
                <g style="opacity:0.68;">
          <g>
            <path style="fill:#FFFFFF;" d="M207.9,185.7l-53.6-25.9l25.9,53.6c-6.2,6.2-6.2,16.1,0,22.1c6.2,6.2,16.1,6.2,22.1,0
              c7.6,7.6,20.1,7.6,27.7,0c7.6-7.6,7.6-20.1,0-27.7c6.2-6.2,6.2-16.1,0-22.1C223.9,179.7,213.9,179.7,207.9,185.7z"/>
          </g>
                  <g>
            <path style="fill:#FFFFFF;" d="M42.1,185.7l53.6-25.9l-25.9,53.6c6.2,6.2,6.2,16.1,0,22.1c-6.2,6.2-16.1,6.2-22.1,0
              c-7.6,7.6-20.1,7.6-27.7,0c-7.6-7.6-7.6-20.1,0-27.7c-6.2-6.2-6.2-16.1,0-22.1C26.1,179.7,36.1,179.7,42.1,185.7z"/>
          </g>
        </g>
                <g>
          <path :style="'fill:#' + ballColor[900]" d="M176,108c6.4-3.2,12-7.1,16.4-11.2c10.7,3.7,18.6,15.2,18.6,28.9c0,16.6-11.6,30.1-26,30.1s-26-13.5-26-30.1
            c0-4.3,0.8-8.4,2.2-12.1C166,112.3,171,110.5,176,108z"/>
                  <path style="fill:#FFFFFF;" d="M182.7,113.1c2.9-1.8,5.7-3.7,8.2-5.7c0.8,0.3,1.6,0.9,2.1,1.6c2.4,3.2,0.3,9-4.8,12.8
            c-5.1,3.9-11.2,4.4-13.7,1.2c-1.1-1.4-1.2-3.3-0.7-5.2C176.8,116.4,179.8,114.9,182.7,113.1z"/>
                  <path :style="'stroke:#' + ballColor[700]" style="fill:none;stroke-width:8;stroke-linecap:round;stroke-miterlimit:10;" d="M194,141.8c0,0-7.9,8.3-17,0"/>
        </g>

      </svg>
      <svg
        v-else-if="type==='money'"
        version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 250 250" style="enable-background:new 0 0 250 250;" xml:space="preserve"
        :class="{
          'ballBounce cursor-pointer block lg:block w-32 h-32': !isPreview,
          'cursor-pointer block lg:block': isPreview
        }"
        >
        <g>
          <circle :style="'fill:#' + ballColor[400]" cx="125" cy="125.8" r="119.5"/>
        </g>
                <g>
          <ellipse :style="'fill:#' + ballColor[300]" cx="125" cy="108.3" rx="114" ry="102"/>
        </g>
                <g>
          <path :style="'fill:#' + ballColor[900]" d="M125,10c30.7,0,59.6,12,81.3,33.7S240,94.3,240,125s-12,59.6-33.7,81.3S155.7,240,125,240s-59.6-12-81.3-33.7
            S10,155.7,10,125s12-59.6,33.7-81.3S94.3,10,125,10 M125,0C56,0,0,56,0,125s56,125,125,125s125-56,125-125S194,0,125,0L125,0z"/>
        </g>
                <path style="opacity:0.75;fill:none;stroke:#FFFFFF;stroke-width:14;stroke-linecap:round;stroke-miterlimit:10;" d="M49,48.5c19-19,45.3-30.8,74.2-30.8"/>
                <path style="opacity:0.5;fill:none;stroke:#FFFFFF;stroke-width:14;stroke-linecap:round;stroke-miterlimit:10;" d="M209.4,192.4c2.2-2.8,4.3-5.7,6.3-8.7"/>
                <path style="opacity:0.5;fill:none;stroke:#FFFFFF;stroke-width:14;stroke-linecap:round;stroke-miterlimit:10;" d="M159.2,226.7c3.2-1,6.4-2.2,9.5-3.5c3.1-1.3,6.1-2.8,9.1-4.4c3-1.6,5.9-3.4,8.7-5.3c2.8-1.9,5.6-4,8.2-6.2"/>
                <g>
          <ellipse style="fill:#B45309;" cx="65" cy="128.6" rx="26" ry="30.1"/>
                  <ellipse style="fill:#FCD34D;" cx="65" cy="125.6" rx="26" ry="30.1"/>
                  <g>
            <defs>
              <ellipse id="SVGID_1_" cx="65" cy="125.6" rx="26" ry="30.1"/>
            </defs>
                    <clipPath id="SVGID_2_">
              <use xlink:href="#SVGID_1_"  style="overflow:visible;"/>
            </clipPath>
                    <g style="clip-path:url(#SVGID_2_);">
                <rect x="30.2" y="108.2" transform="matrix(0.9522 -0.3055 0.3055 0.9522 -31.1345 23.1283)" style="fill:#FEF3C7;" width="56.2" height="5.7"/>
                      <rect x="31.5" y="114.3" transform="matrix(0.9522 -0.3055 0.3055 0.9522 -32.3238 23.7261)" style="fill:#FEF3C7;" width="56.2" height="1.7"/>
                      <rect x="41.7" y="146" transform="matrix(0.9522 -0.3055 0.3055 0.9522 -41.5214 28.3492)" style="fill:#FEF3C7;" width="56.2" height="1.7"/>
                      <rect x="41" y="143.8" transform="matrix(0.9522 -0.3055 0.3055 0.9522 -40.8871 28.0303)" style="fill:#FEF3C7;" width="56.2" height="1.7"/>
                      <rect x="40.3" y="141.9" transform="matrix(0.9522 -0.3055 0.3055 0.9522 -40.2924 27.7314)" style="fill:#FEF3C7;" width="56.2" height="1.4"/>
                      <rect x="28.7" y="105.5" transform="matrix(0.9522 -0.3055 0.3055 0.9522 -29.7865 22.4508)" style="fill:#FEF3C7;" width="56.2" height="1.7"/>
            </g>
          </g>
                  <ellipse style="fill:#854D0E;" cx="65" cy="125.6" rx="20.6" ry="23.9"/>
                  <ellipse style="fill:#FCD34D;" cx="66" cy="127.6" rx="20.6" ry="23.9"/>
                  <g>
            <g>
              <path style="fill:#D97706;" d="M56.8,132.9c3,1.9,5.6,3,8.7,3c2.3,0,3.5-0.8,3.5-2.2c0-4-14-3.3-14-12.1c0-4.9,4.1-8,10.6-8
                c4.4,0,7.3,1.7,9.7,4.1l-3.5,4c-2.3-1.6-4-2.4-6.7-2.4c-2.1,0-3.3,0.8-3.3,2.2c0,3.4,14,3,14,11.7c0,4.7-4,8.4-11.4,8.4
                c-3.4,0-7.9-1.5-10.7-3.9L56.8,132.9z M62.8,107.5h4.7v8.3h-4.7V107.5z M67.5,138.7v9.8h-4.7v-9.8H67.5z"/>
            </g>
          </g>
        </g>
                <g>
          <ellipse style="fill:#B45309;" cx="185" cy="128.6" rx="26" ry="30.1"/>
                  <ellipse style="fill:#FCD34D;" cx="185" cy="125.6" rx="26" ry="30.1"/>
                  <g>
            <defs>
              <ellipse id="SVGID_3_" cx="185" cy="125.6" rx="26" ry="30.1"/>
            </defs>
                    <clipPath id="SVGID_4_">
              <use xlink:href="#SVGID_3_"  style="overflow:visible;"/>
            </clipPath>
                    <g style="clip-path:url(#SVGID_4_);">

                <rect x="150.2" y="108.2" transform="matrix(0.9522 -0.3055 0.3055 0.9522 -25.3961 59.7927)" style="fill:#FEF3C7;" width="56.2" height="5.7"/>
                      <rect x="151.5" y="114.3" transform="matrix(0.9522 -0.3055 0.3055 0.9522 -26.5855 60.3905)" style="fill:#FEF3C7;" width="56.2" height="1.7"/>
                      <rect x="161.7" y="146" transform="matrix(0.9522 -0.3055 0.3055 0.9522 -35.7831 65.0135)" style="fill:#FEF3C7;" width="56.2" height="1.7"/>
                      <rect x="161" y="143.8" transform="matrix(0.9522 -0.3055 0.3055 0.9522 -35.1487 64.6947)" style="fill:#FEF3C7;" width="56.2" height="1.7"/>
                      <rect x="160.3" y="141.9" transform="matrix(0.9522 -0.3055 0.3055 0.9522 -34.5541 64.3958)" style="fill:#FEF3C7;" width="56.2" height="1.4"/>
                      <rect x="148.7" y="105.5" transform="matrix(0.9522 -0.3055 0.3055 0.9522 -24.0482 59.1152)" style="fill:#FEF3C7;" width="56.2" height="1.7"/>
            </g>
          </g>
                  <ellipse style="fill:#854D0E;" cx="185" cy="125.6" rx="20.6" ry="23.9"/>
                  <ellipse style="fill:#FCD34D;" cx="186" cy="127.6" rx="20.6" ry="23.9"/>
                  <g>
            <g>
              <path style="fill:#D97706;" d="M176.8,132.9c3,1.9,5.6,3,8.7,3c2.3,0,3.5-0.8,3.5-2.2c0-4-14-3.3-14-12.1c0-4.9,4.1-8,10.6-8
                c4.4,0,7.3,1.7,9.7,4.1l-3.5,4c-2.3-1.6-4-2.4-6.7-2.4c-2.1,0-3.3,0.8-3.3,2.2c0,3.4,14,3,14,11.7c0,4.7-4,8.4-11.4,8.4
                c-3.4,0-7.9-1.5-10.7-3.9L176.8,132.9z M182.8,107.5h4.7v8.3h-4.7V107.5z M187.5,138.7v9.8h-4.7v-9.8H187.5z"/>
            </g>
          </g>
        </g>
                <path :style="'fill:#' + ballColor[900]" d="M157.5,154.5c0,17.9-14.6,32.5-32.5,32.5s-32.5-14.6-32.5-32.5"/>
                <path :style="'fill:#' + ballColor[900]" d="M138.7,181.2"/>
                <path :style="'fill:#' + ballColor[900]" d="M111.3,181.2"/>
                <path style="fill:#15803D;" d="M145,165v23c0,11-9,20-20,20h0c-11,0-20-9-20-20v-23"/>
                <path style="fill:#16A34A;" d="M145,163v23c0,11-9,20-20,20h0c-11,0-20-9-20-20v-23"/>
                <g>
          <g>
            <path style="fill:#365314;" d="M119.8,185.5c1.9,1.2,3.6,1.9,5.5,1.9c1.5,0,2.3-0.5,2.3-1.4c0-2.6-8.9-2.1-8.9-7.7c0-3.1,2.6-5.1,6.7-5.1
              c2.8,0,4.7,1.1,6.2,2.6l-2.2,2.6c-1.4-1-2.6-1.5-4.2-1.5c-1.4,0-2.1,0.5-2.1,1.4c0,2.2,8.9,1.9,8.9,7.5c0,3-2.6,5.3-7.2,5.3
              c-2.2,0-5-0.9-6.8-2.5L119.8,185.5z M123.6,169.3h3v5.3h-3V169.3z M126.6,189.2v6.2h-3v-6.2H126.6z"/>
          </g>
        </g>

        </svg>
      <svg
        v-else-if="type==='owl'"
        version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 250 250" style="enable-background:new 0 0 250 250;" xml:space="preserve"
        :class="{
          'ballBounce cursor-pointer block lg:block w-32 h-32': !isPreview,
          'cursor-pointer block lg:block': isPreview
        }"
      >
        <g>
          <circle :style="'fill:#' + ballColor[400]" cx="125" cy="125.8" r="119.5"/>
        </g>
                <g>
          <polygon :style="'fill:#' + ballColor[300]" points="26,37 19,44 29,69 63,29 20,18 	"/>
        </g>
                <g>
          <polygon :style="'fill:#' + ballColor[300]" points="224,37 231,44 221,69 187,29 230,18 	"/>
        </g>
                <g>
          <ellipse :style="'fill:#' + ballColor[300]" cx="125" cy="108.3" rx="114" ry="102"/>
        </g>
                <path style="opacity:0.5;fill:#FFFFFF;" d="M9.9,108.9c0-36.2,17.2-54.8,47.5-50.5C83,62,115,72,120.9,132.9c3.5,36-24.8,41.5-55.5,41.5
          c-16.3,0-40.2-3.7-50.4-17C6.1,145.8,9.9,125.8,9.9,108.9z"/>
                <path style="opacity:0.5;fill:#FFFFFF;" d="M240.2,108.9c0-36.2-17.2-54.8-47.5-50.5c-25.6,3.6-57.6,13.6-63.5,74.5c-3.5,36,24.8,41.5,55.5,41.5
          c16.3,0,40.2-3.7,50.4-17C244,145.8,240.2,125.8,240.2,108.9z"/>
                <g>
          <path :style="'fill:#' + ballColor[900]" d="M125,10c24.1,0,47.1,7.3,66.6,21.2l3.6,2.6l4.3-0.9c9.8-2.1,18.2-4.6,25.5-7.6c-0.6,1.1-1.2,2.1-1.9,3.2
            l-10.1,16.7l11.5-0.9l-3.9,5.6l-3.9,5.6l3.8,5.7C233.3,80,240,102.1,240,125c0,30.7-12,59.6-33.7,81.3C184.6,228,155.7,240,125,240
            s-59.6-12-81.3-33.7C22,184.6,10,155.7,10,125c0-22.9,6.7-45,19.4-63.9l3.8-5.7l-3.9-5.6l-3.9-5.6l11.5,0.9L26.8,28.4
            c-0.6-1-1.2-2.1-1.9-3.2c7.3,3,15.8,5.5,25.5,7.6l4.3,0.9l3.6-2.6C77.9,17.4,100.9,10,125,10 M125,0C98,0,73,8.6,52.6,23.1
            c-16.5-3.6-31.1-8.8-41.9-17.4C10.4,5.6,10,5.6,9.7,5.6c-1.9,0-3.4,1.8-3,3.8c3,8.3,6.7,16.4,11.5,24.2c-4.5-0.4-9.1-1-13.7-1.9
            l16.5,23.8C7.8,75.4,0,99.3,0,125c0,69,56,125,125,125s125-56,125-125c0-25.7-7.8-49.6-21.1-69.5l16.5-23.8
            c-4.6,0.9-9.2,1.5-13.7,1.9c4.7-7.8,8.5-15.9,11.5-24.2c0.4-2-1.1-3.8-3-3.8c-0.3,0-0.6,0-0.9,0.1c-10.8,8.6-25.5,13.8-41.9,17.4
            C177,8.6,152,0,125,0L125,0z"/>
        </g>
                <path style="opacity:0.75;fill:none;stroke:#FFFFFF;stroke-width:14;stroke-linecap:round;stroke-miterlimit:10;" d="M49,48.5c19-19,45.3-30.8,74.2-30.8"/>
                <path style="opacity:0.5;fill:none;stroke:#FFFFFF;stroke-width:14;stroke-linecap:round;stroke-miterlimit:10;" d="M209.4,192.4c2.2-2.8,4.3-5.7,6.3-8.7"/>
                <path style="opacity:0.5;fill:none;stroke:#FFFFFF;stroke-width:14;stroke-linecap:round;stroke-miterlimit:10;" d="M159.2,226.7c3.2-1,6.4-2.2,9.5-3.5c3.1-1.3,6.1-2.8,9.1-4.4c3-1.6,5.9-3.4,8.7-5.3c2.8-1.9,5.6-4,8.2-6.2"/>
                <g>
          <ellipse :style="'fill:#' + ballColor[900]" cx="65" cy="125.6" rx="26" ry="30.1"/>
                  <ellipse style="fill:#FFFFFF;" cx="64.2" cy="108.9" rx="11.6" ry="7.3"/>
                  <path :style="'stroke:#' + ballColor[700]" style="fill:none;stroke-width:8;stroke-linecap:round;stroke-miterlimit:10;" d="M56,141.8c0,0,7.9,8.3,17,0"/>
        </g>
                <g>
          <ellipse :style="'fill:#' + ballColor[900]" cx="185" cy="125.8" rx="26" ry="30.1"/>
                  <ellipse style="fill:#FFFFFF;" cx="184.2" cy="109.1" rx="11.6" ry="7.3"/>
                  <path :style="'stroke:#' + ballColor[700]" style="fill:none;stroke-width:8;stroke-linecap:round;stroke-miterlimit:10;" d="M176,142c0,0,7.9,8.3,17,0"/>
        </g>
                <path :style="'fill:#' + ballColor[900] + ';stroke:#' + ballColor[900]" style="stroke-width:4.0416;stroke-linecap:round;stroke-miterlimit:10;" d="M135,147.6c0,7.2-3.9,17.4-10,17.4c-6.1,0-10-10.3-10-17.4c0-7.2,3.9-8.6,10-8.6C131.1,139,135,140.4,135,147.6
          z"/>
                <g>
          <line :style="'stroke:#' + ballColor[900]" style="fill:none;stroke-width:10;stroke-linecap:round;stroke-miterlimit:10;" x1="125" y1="209" x2="125" y2="219"/>
        </g>
                <g>
          <line :style="'stroke:#' + ballColor[900]" style="fill:none;stroke-width:10;stroke-linecap:round;stroke-miterlimit:10;" x1="155" y1="209" x2="155" y2="219"/>
        </g>
                <g>
          <line :style="'stroke:#' + ballColor[900]" style="fill:none;stroke-width:10;stroke-linecap:round;stroke-miterlimit:10;" x1="95" y1="209" x2="95" y2="219"/>
        </g>
                <g>
          <line :style="'stroke:#' + ballColor[900]" style="fill:none;stroke-width:10;stroke-linecap:round;stroke-miterlimit:10;" x1="110" y1="229" x2="110" y2="239"/>
        </g>
                <g>
          <line :style="'stroke:#' + ballColor[900]" style="fill:none;stroke-width:10;stroke-linecap:round;stroke-miterlimit:10;" x1="140" y1="229" x2="140" y2="239"/>
        </g>
      </svg>
      <svg
        v-else-if="type==='bear'"
        version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 250 250" style="enable-background:new 0 0 250 250;" xml:space="preserve"
        :class="{
          'ballBounce cursor-pointer block lg:block w-32 h-32': !isPreview,
          'cursor-pointer block lg:block': isPreview
        }"
      >
        <g>
          <path :style="'fill:#' + ballColor[400]" d="M244.5,125.8c0,66-5.5,119.2-119.5,119.5C11,245.5,5.5,191.8,5.5,125.8S59,6.3,125,6.3S244.5,59.8,244.5,125.8
            z"/>
        </g>
                <g>
          <circle :style="'fill:#' + ballColor[300]" cx="35" cy="35.5" r="30.5"/>
        </g>
                <g>
          <circle :style="'fill:#' + ballColor[300]" cx="215" cy="35.5" r="30.5"/>
        </g>
                <g>
          <circle :style="'fill:#' + ballColor[400]" cx="36" cy="37" r="16"/>
        </g>
                <g>
          <circle :style="'fill:#' + ballColor[400]" cx="214" cy="37" r="16"/>
        </g>
                <g>
          <ellipse :style="'fill:#' + ballColor[300]" cx="125" cy="108.3" rx="114" ry="102"/>
        </g>
                <g>
          <path :style="'fill:#' + ballColor[900]" d="M215,10c14.1,0,25.5,11.4,25.5,25.5c0,8.5-4.2,16.5-11.4,21.2l-7.6,5.1l4.4,8c9.2,16.8,14.1,35.9,14.1,55.2
            c0,35.3-3,62.8-17.7,82.4c-7.8,10.4-18.6,18.1-33.1,23.4c-16.5,6.1-38,9.2-64.2,9.2c-26.1,0-47.7-3.1-64.1-9.2
            c-14.5-5.4-25.3-13-33.1-23.4C13,187.8,10,160.3,10,125c0-19.3,4.9-38.4,14.1-55.2l4.4-8l-7.6-5.1C13.7,52,9.5,44,9.5,35.5
            C9.5,21.4,20.9,10,35,10c8.5,0,16.4,4.2,21.1,11.2l5.1,7.6l8-4.4C86.2,15,105.5,10,125,10s38.8,5,55.7,14.4l8,4.4l5.1-7.6
            C198.6,14.2,206.5,10,215,10 M215,0c-12.2,0-23.1,6.2-29.4,15.6C167.6,5.7,147,0,125,0S82.4,5.7,64.4,15.6C58,6.2,47.2,0,35,0
            C15.4,0-0.5,15.9-0.5,35.5c0,12.3,6.3,23.2,15.8,29.5C5.6,82.8,0,103.3,0,125c0,69,11,125,125,125c0,0,0,0,0,0
            c114,0,125-56,125-125c0-21.7-5.6-42.2-15.3-60c9.5-6.4,15.8-17.2,15.8-29.5C250.5,15.9,234.6,0,215,0L215,0z"/>
        </g>
                <path style="opacity:0.75;fill:none;stroke:#FFFFFF;stroke-width:14;stroke-linecap:round;stroke-miterlimit:10;" d="M49,48.5c19-19,45.3-30.8,74.2-30.8"/>
                <path style="opacity:0.5;fill:none;stroke:#FFFFFF;stroke-width:14;stroke-linecap:round;stroke-miterlimit:10;" d="M212.1,206.2c2.4-2.6,4.6-5.4,6.8-8.3"/>
                <path style="opacity:0.5;fill:none;stroke:#FFFFFF;stroke-width:14;stroke-linecap:round;stroke-miterlimit:10;" d="M157.6,229.9c3.3-0.3,6.7-0.8,10-1.5c3.3-0.7,6.6-1.5,9.8-2.5s6.4-2.1,9.6-3.4s6.3-2.8,9.3-4.4"/>
                <g>
          <ellipse :style="'fill:#' + ballColor[900]" cx="65" cy="125.6" rx="26" ry="30.1"/>
                  <ellipse style="fill:#FFFFFF;" cx="64.2" cy="108.9" rx="11.6" ry="7.3"/>
                  <path :style="'stroke:#' + ballColor[700]" style="fill:none;stroke-width:8;stroke-linecap:round;stroke-miterlimit:10;" d="M56,141.8c0,0,7.9,8.3,17,0"/>
        </g>
                <g>
          <ellipse :style="'fill:#' + ballColor[900]" cx="185" cy="125.8" rx="26" ry="30.1"/>
                  <ellipse style="fill:#FFFFFF;" cx="184.2" cy="109.1" rx="11.6" ry="7.3"/>
                  <path :style="'stroke:#' + ballColor[700]" style="fill:none;stroke-width:8;stroke-linecap:round;stroke-miterlimit:10;" d="M176,142c0,0,7.9,8.3,17,0"/>
        </g>
                <ellipse style="opacity:0.5;fill:#FFFFFF;enable-background:new;" cx="125" cy="154" rx="30.2" ry="31.5"/>
                <g>
          <path :style="'stroke:#' + ballColor[900]" style="fill:none;stroke-width:6;stroke-linecap:round;stroke-miterlimit:10;" d="M101.9,163.8c0,0,11.6,16.8,23.1,0"/>
                  <path :style="'stroke:#' + ballColor[900]" style="fill:none;stroke-width:6;stroke-linecap:round;stroke-miterlimit:10;" d="M124.9,163.8c0,0,11.6,16.8,23.1,0"/>
                  <path :style="'fill:#' + ballColor[900] + ';stroke:#' + ballColor[900]" style="stroke-width:10;stroke-linecap:round;stroke-miterlimit:10;" d="M135.9,138.8c0,4.7-4.7,8.5-10.5,8.5s-10.5-3.8-10.5-8.5s4.7,0.5,10.5,0.5S135.9,134.1,135.9,138.8z"/>
                  <line :style="'stroke:#' + ballColor[900]" style="fill:none;stroke-width:6;stroke-linecap:round;stroke-miterlimit:10;" x1="125.2" y1="142.3" x2="125.2" y2="164.3"/>
        </g>
      </svg>
      <svg
        v-else-if="type==='cat'"
        version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 250 250" style="enable-background:new 0 0 250 250;" xml:space="preserve"
        :class="{
          'ballBounce cursor-pointer block lg:block w-32 h-32': !isPreview,
          'cursor-pointer block lg:block': isPreview
        }"
      >
        <g id="Layer_1">
          <g>
            <circle :style="'fill:#' + ballColor[400]" cx="125" cy="125.8" r="119.5"/>
          </g>
          <g>
            <path :style="'fill:#' + ballColor[300]" d="M7.4,14.9l13,61.6c1.1,5.1,7.3,7.2,11.2,3.7L78.5,38c3.9-3.5,2.5-9.9-2.4-11.6L16.2,7
              C11.2,5.4,6.3,9.7,7.4,14.9z"/>
          </g>
          <g>
            <path :style="'fill:#' + ballColor[300]" d="M242.6,14.9l-13,61.6c-1.1,5.1-7.3,7.2-11.2,3.7L171.5,38c-3.9-3.5-2.5-9.9,2.4-11.6L233.8,7
              C238.8,5.4,243.7,9.7,242.6,14.9z"/>
          </g>
          <g>
            <path :style="'fill:#' + ballColor[400]" d="M227.1,28l-6.4,30.4c-0.5,2.5-3.6,3.5-5.5,1.8L192,39.4c-1.9-1.7-1.3-4.9,1.2-5.7l29.5-9.6
              C225.2,23.3,227.6,25.4,227.1,28z"/>
          </g>
          <g>
            <path :style="'fill:#' + ballColor[400]" d="M22.9,28l6.4,30.4c0.5,2.5,3.6,3.5,5.5,1.8L58,39.4c1.9-1.7,1.3-4.9-1.2-5.7l-29.5-9.6
              C24.8,23.3,22.4,25.4,22.9,28z"/>
          </g>
          <g>
            <ellipse :style="'fill:#' + ballColor[300]" cx="125" cy="108.3" rx="114" ry="102"/>
          </g>
          <path :style="'fill:#' + ballColor[900]" d="M198.7,181.7l13.8,17.5c3,3.8,8.8,3.6,11.5-0.4l7.5-11c2.7-4,0.8-9.5-3.8-10.9l-21.3-6.5
            C199.7,168.4,194.4,176.2,198.7,181.7z"/>
          <path :style="'fill:#' + ballColor[900]" d="M51.3,181.7l-13.8,17.5c-3,3.8-8.8,3.6-11.5-0.4l-7.5-11c-2.7-4-0.8-9.5,3.8-10.9l21.3-6.5
            C50.3,168.4,55.6,176.2,51.3,181.7z"/>
          <path :style="'fill:#' + ballColor[900]" d="M25.1,139.7L9.6,146c-3.4,1.3-7-0.9-7.3-4.5l-0.8-9.9c-0.3-3.6,3-6.4,6.5-5.6l16.3,3.7
            C29.4,130.7,30,137.8,25.1,139.7z"/>
          <path :style="'fill:#' + ballColor[900]" d="M224.9,139.7l15.5,6.2c3.4,1.3,7-0.9,7.3-4.5l0.8-9.9c0.3-3.6-3-6.4-6.5-5.6l-16.3,3.7
            C220.6,130.7,220,137.8,224.9,139.7z"/>
          <g>
            <path :style="'fill:#' + ballColor[900]" d="M125,10c21.2,0,42,5.8,59.9,16.8l3.9,2.4l4.4-1.4l43.3-14.2l-10.7,51.1l-0.7,3.5l1.7,3.2
              c8.6,16.4,13.2,34.9,13.2,53.5c0,30.7-12,59.6-33.7,81.3C184.6,228,155.7,240,125,240s-59.6-12-81.3-33.7
              C22,184.6,10,155.7,10,125c0-18.6,4.6-37.2,13.2-53.5l1.7-3.2l-0.7-3.5L13.4,13.6l43.3,14.2l4.4,1.4l3.9-2.4
              C83,15.8,103.8,10,125,10 M125,0c-23.9,0-46.2,6.7-65.2,18.3L13.5,3.1c-0.9-0.3-1.7-0.4-2.6-0.4c-5,0-9.1,4.6-8,9.9l11.4,54.2
              C5.2,84.2,0,104,0,125c0,69,56,125,125,125s125-56,125-125c0-21-5.2-40.8-14.3-58.2l11.4-54.2c1.1-5.3-3-9.9-8-9.9
              c-0.8,0-1.7,0.1-2.6,0.4l-46.3,15.2C171.2,6.7,148.9,0,125,0L125,0z"/>
          </g>
          <path style="opacity:0.75;fill:none;stroke:#FFFFFF;stroke-width:14;stroke-linecap:round;stroke-miterlimit:10;" d="M49,48.5c19-19,45.3-30.8,74.2-30.8"/>
          <path style="opacity:0.5;fill:none;stroke:#FFFFFF;stroke-width:14;stroke-linecap:round;stroke-miterlimit:10;" d="M209.4,192.4c2.2-2.8,4.3-5.7,6.3-8.7"/>
          <path style="opacity:0.5;fill:none;stroke:#FFFFFF;stroke-width:14;stroke-linecap:round;stroke-miterlimit:10;" d="M159.2,226.7c3.2-1,6.4-2.2,9.5-3.5c3.1-1.3,6.1-2.8,9.1-4.4c3-1.6,5.9-3.4,8.7-5.3c2.8-1.9,5.6-4,8.2-6.2"/>
          <g>
            <ellipse :style="'fill:#' + ballColor[900]" cx="65" cy="125.6" rx="26" ry="30.1"/>
            <ellipse style="fill:#FFFFFF;" cx="64.2" cy="108.9" rx="11.6" ry="7.3"/>
            <path :style="'stroke:#' + ballColor[700]" style="fill:none;stroke-width:8;stroke-linecap:round;stroke-miterlimit:10;" d="M56,141.8c0,0,7.9,8.3,17,0"/>
          </g>
          <g>
            <ellipse :style="'fill:#' + ballColor[900]" cx="185" cy="125.8" rx="26" ry="30.1"/>
            <ellipse style="fill:#FFFFFF;" cx="184.2" cy="109.1" rx="11.6" ry="7.3"/>
            <path :style="'stroke:#' + ballColor[700]" style="fill:none;stroke-width:8;stroke-linecap:round;stroke-miterlimit:10;" d="M176,142c0,0,7.9,8.3,17,0"/>
          </g>
          <g>
            <ellipse style="opacity:0.5;fill:#FFFFFF;enable-background:new;" cx="125" cy="154" rx="29.6" ry="30.9"/>
            <path :style="'stroke:#' + ballColor[900]" style="fill:none;stroke-width:5.8869;stroke-linecap:round;stroke-miterlimit:10;" d="M102,163.8c0,0,11.4,16.5,22.7,0"/>
            <path :style="'stroke:#' + ballColor[900]" style="fill:none;stroke-width:5.8869;stroke-linecap:round;stroke-miterlimit:10;" d="M124.6,163.8c0,0,11.4,16.5,22.7,0"/>
            <path :style="'fill:#' + ballColor[900] + ';stroke:#' + ballColor[900]" style="stroke-width:6.5618;stroke-linecap:round;stroke-miterlimit:10;" d="M131.8,142c0,3.1-3.1,5.6-6.9,5.6s-6.9-2.5-6.9-5.6c0-3.1,3.1,0.3,6.9,0.3S131.8,139,131.8,142z"/>
            <line :style="'stroke:#' + ballColor[900]" style="fill:none;stroke-width:5.8869;stroke-linecap:round;stroke-miterlimit:10;" x1="124.9" y1="142.7" x2="124.9" y2="164.3"/>
          </g>
          <g>
            <line :style="'stroke:#' + ballColor[900]" style="fill:none;stroke-width:10;stroke-linecap:round;stroke-miterlimit:10;" x1="125" y1="8" x2="125" y2="33"/>
            <line :style="'stroke:#' + ballColor[900]" style="fill:none;stroke-width:10;stroke-linecap:round;stroke-miterlimit:10;" x1="145" y1="8" x2="143" y2="33"/>
            <line :style="'stroke:#' + ballColor[900]" style="fill:none;stroke-width:10;stroke-linecap:round;stroke-miterlimit:10;" x1="105" y1="8" x2="107" y2="33"/>
          </g>
        </g>
      </svg>
      <svg
        v-else-if="type==='pumpkin'"
        version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 250 250" style="enable-background:new 0 0 250 250;" xml:space="preserve"
        :class="{
          'ballBounce cursor-pointer block lg:block w-32 h-32': !isPreview,
          'cursor-pointer block lg:block': isPreview
        }"
      >
        <g>
          <g>
            <path :style="'fill:#' + ballColor[400]" d="M244.5,145.8c0,66-53.5,99.5-119.5,99.5S5.5,211.8,5.5,145.8S19,26,125,26.3C231,26.7,244.5,79.8,244.5,145.8
              z"/>
          </g>
          <g>
            <path :style="'fill:#' + ballColor[300]" d="M239,118.3c0,56.3-51,102-114,102s-114-45.7-114-102s51-95,114-95S239,61.9,239,118.3z"/>
          </g>
          <g>
            <path :style="'fill:#' + ballColor[900]" d="M125,30c48.4,0,80.2,11.9,97,36.3c15.8,22.9,18,55.2,18,86.7c0,29.1-10.9,51.4-32.3,66.2
              C188,232.8,159.4,240,125,240s-63-7.2-82.7-20.8C20.9,204.4,10,182.1,10,153c0-31.5,2.2-63.8,18-86.7C44.8,41.9,76.6,30,125,30
              L125,30 M125,20C11,20,0,84,0,153c0,69,56,97,125,97s125-28,125-97S239,20,125,20C125,20,125,20,125,20L125,20z"/>
          </g>
          <g>
            <path :style="'stroke:#' + ballColor[900]" style="fill:none;stroke-width:6.1173;stroke-linecap:round;stroke-miterlimit:10;" d="M199,152c0,55.5-23,92-74,91.5c-52-0.5-74-36-74-91.5S84.1,41.5,125,41.5S199,96.5,199,152z"/>
            <path :style="'stroke:#' + ballColor[900]" style="fill:none;stroke-width:6.1173;stroke-linecap:round;stroke-miterlimit:10;" d="M130.9,42c127-32,140,187,0,204"/>
            <path :style="'stroke:#' + ballColor[900]" style="fill:none;stroke-width:6.1173;stroke-linecap:round;stroke-miterlimit:10;" d="M119.1,42c-127-32-140,187,0,204"/>
          </g>
          <path style="opacity:0.5;fill:none;stroke:#FFFFFF;stroke-width:14;stroke-linecap:round;stroke-miterlimit:10;" d="M33,73.5C48,54,68,40,96,39.8"/>
          <path style="opacity:0.5;fill:none;stroke:#FFFFFF;stroke-width:14;stroke-linecap:round;stroke-miterlimit:10;" d="M220.6,197.6c2.1-2.9,4.1-5.9,5.9-9"/>
          <path style="opacity:0.5;fill:none;stroke:#FFFFFF;stroke-width:14;stroke-linecap:round;stroke-miterlimit:10;" d="M171.1,229.6c3.2-1,6.4-2.1,9.5-3.4c3.1-1.3,6.2-2.7,9.2-4.3c3-1.6,5.9-3.3,8.7-5.2c2.8-1.9,5.6-3.9,8.3-6.1"
          />
          <g>
            <ellipse :style="'fill:#' + ballColor[900]" cx="65" cy="135.6" rx="26" ry="30.1"/>
            <ellipse style="fill:#FFFFFF;" cx="64.2" cy="118.9" rx="11.6" ry="7.3"/>
            <path :style="'stroke:#' + ballColor[700]" style="fill:none;stroke-width:8;stroke-linecap:round;stroke-miterlimit:10;" d="M56,151.8c0,0,7.9,8.3,17,0"/>
          </g>
          <g>
            <ellipse :style="'fill:#' + ballColor[900]" cx="185" cy="135.8" rx="26" ry="30.1"/>
            <ellipse style="fill:#FFFFFF;" cx="184.2" cy="119.1" rx="11.6" ry="7.3"/>
            <path :style="'stroke:#' + ballColor[700]" style="fill:none;stroke-width:8;stroke-linecap:round;stroke-miterlimit:10;" d="M176,152c0,0,7.9,8.3,17,0"/>
          </g>
        </g>
        <g>
          <path :style="'fill:#' + ballColor[900] + ';stroke:#' + ballColor[900]" style="stroke-width:10;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="M125,218.6c-20.8,0-52.7-42.3-52.7-42.3c36.7,17.2,72,19,105.3,0C177.7,176.4,145.8,218.6,125,218.6z"/>
          <polyline :style="'fill:#' + ballColor[300]" points="139.6,183.6 161,178 155,200 	"/>
          <polyline :style="'fill:#' + ballColor[300]" points="108.3,182.4 87,176.7 93,198.7 	"/>
        </g>
        <g>
          <path style="fill:#10B981;stroke:#047857;stroke-width:6.1237;stroke-linecap:round;stroke-miterlimit:10;" d="M142,41.5c-8.4,4.6-16.7,4.6-25.1,0C107.1,30,127.8,3,127.8,3H142C135.9,17,135.6,30.9,142,41.5z"/>
        </g>
      </svg>
      <svg
        v-else-if="type==='dragon'"
        version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 250 250" style="enable-background:new 0 0 250 250;" xml:space="preserve"
        :class="{
          'ballBounce cursor-pointer block lg:block w-32 h-32': !isPreview,
          'cursor-pointer block lg:block': isPreview
        }"
      >
        <g>
          <path :style="'fill:#' + ballColor[400]" d="M244.5,125.8c0,66-26.5,119.2-119.5,119.5C39,245.5,5.5,191.8,5.5,125.8S59,17.3,125,17.3
            S244.5,59.8,244.5,125.8z"/>
        </g>
                <g>
          <path :style="'fill:#' + ballColor[300]" d="M239,99.8c0,50.9,0,126.2-114,126.2S11,150.7,11,99.8s51-79.5,114-79.5S239,48.9,239,99.8z"/>
        </g>
                <path :style="'fill:#' + ballColor[300]" d="M201.1,9.3c25.1,17.1,36.3,36.2,27.3,58.1L198,57.2L201.1,9.3z"/>
                <path :style="'fill:#' + ballColor[300]" d="M48.4,9.3C23.3,26.5,12.1,45.5,21.1,67.4l30.3-10.2L48.4,9.3z"/>
                <path :style="'fill:#' + ballColor[400]" d="M146.4,94.7c0,9.2-9.6,19.7-21.4,19.7s-21.4-10.5-21.4-19.7S113.2,78,125,78S146.4,85.5,146.4,94.7z"/>
                <path :style="'fill:#' + ballColor[400]" d="M158,32.5c0,15.8-14.8,28.6-33,28.6S92,48.3,92,32.5s14.8-18.6,33-18.6S158,16.7,158,32.5z"/>
                <circle :style="'fill:#' + ballColor[400]" cx="158" cy="70" r="8"/>
                <circle :style="'fill:#' + ballColor[400]" cx="93" cy="69" r="9"/>
                <circle :style="'fill:#' + ballColor[400]" cx="81" cy="46" r="4"/>
                <circle :style="'fill:#' + ballColor[400]" cx="170" cy="45" r="7"/>
                <g>
          <path :style="'fill:#' + ballColor[900]" d="M204.5,17.2C220,29.2,231.4,46,226.4,65.1l-1,3.7l1.8,3.4c8.5,16.4,12.8,34.2,12.8,52.8
            c0,77.4-37.6,115-115,115S10,202.4,10,125c0-18.6,4.3-36.4,12.8-52.8l1.8-3.4l-1-3.7C18.6,46,30,29.2,45.5,17.2l-0.1,12.9l-0.2,19
            l15.7-10.6C79.9,25.7,102,19,125,19s45.1,6.7,64.1,19.5l15.7,10.6l-0.2-19L204.5,17.2 M194.4-1l0.2,31.2C174.7,16.8,150.8,9,125,9
            s-49.7,7.8-69.6,21.2L55.6-1C27,13.8,6.5,39.3,13.9,67.6C5,84.8,0,104.3,0,125c0,95.7,56,125,125,125s125-29.3,125-125
            c0-20.7-5-40.2-13.9-57.4C243.5,39.3,223,13.8,194.4-1L194.4-1z"/>
        </g>
                <path style="fill:#FFB290;" d="M150,206.5c0,6.9-11.9,19.5-26.5,19.5S97,213.4,97,206.5s11.9,2.5,26.5,2.5S150,199.6,150,206.5z"/>
                <path style="fill:#FFD5C0;" d="M147,211.5c0,6.9-8.9,14.5-23.5,14.5s-22.5-7.6-22.5-14.5s7.9,4.5,22.5,4.5S147,204.6,147,211.5z"/>
                <path style="opacity:0.75;fill:none;stroke:#FFFFFF;stroke-width:14;stroke-linecap:round;stroke-miterlimit:10;" d="M49,57.5c19-19,45.3-30.8,74.2-30.8"/>
                <path style="opacity:0.5;fill:none;stroke:#FFFFFF;stroke-width:14;stroke-linecap:round;stroke-miterlimit:10;" d="M215.4,195.4c2.2-2.8,4.3-5.7,6.3-8.7"/>
                <path style="opacity:0.5;fill:none;stroke:#FFFFFF;stroke-width:14;stroke-linecap:round;stroke-miterlimit:10;" d="M166.2,228.7c3.2-1,6.4-2.2,9.5-3.5c3.1-1.3,6.1-2.8,9.1-4.4c3-1.6,5.9-3.4,8.7-5.3c2.8-1.9,5.6-4,8.2-6.2"/>
                <ellipse :style="'fill:#' + ballColor[400]" cx="185" cy="137.5" rx="29" ry="32.5"/>
                <ellipse :style="'fill:#' + ballColor[400]" cx="65" cy="137.5" rx="29" ry="32.5"/>
                <g>
          <ellipse :style="'fill:#' + ballColor[900]" cx="65" cy="135.6" rx="26" ry="30.1"/>
                  <ellipse style="fill:#FFFFFF;" cx="64.2" cy="118.9" rx="11.6" ry="7.3"/>
                  <path :style="'stroke:#' + ballColor[700]" style="fill:none;stroke-width:8;stroke-linecap:round;stroke-miterlimit:10;" d="M56,151.8c0,0,7.9,8.3,17,0"/>
        </g>
                <g>
          <ellipse :style="'fill:#' + ballColor[900]" cx="185" cy="135.8" rx="26" ry="30.1"/>
                  <ellipse style="fill:#FFFFFF;" cx="184.2" cy="119.1" rx="11.6" ry="7.3"/>
                  <path :style="'stroke:#' + ballColor[700]" style="fill:none;stroke-width:8;stroke-linecap:round;stroke-miterlimit:10;" d="M176,152c0,0,7.9,8.3,17,0"/>
        </g>
                <circle :style="'fill:#' + ballColor[900]" cx="135" cy="175" r="4"/>
                <circle :style="'fill:#' + ballColor[900]" cx="115" cy="175" r="4"/>
                <path :style="'stroke:#' + ballColor[900]" style="fill:none;stroke-width:7;stroke-linecap:round;stroke-miterlimit:10;" d="M105.5,159.5c0,0,10-3,20-3c11,0,20,3,20,3"/>
                <path :style="'stroke:#' + ballColor[900]" style="fill:none;stroke-width:7;stroke-linecap:round;stroke-miterlimit:10;" d="M61.4,187.8c9.9,5,20,14.8,30.2,17.3c10.3,2.5,11.8,19.9,31.3,19.9c22.5,0,21.4-17.2,32.3-19.7
          c10.9-2.5,22-12.3,33.3-17.4"/>
                <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="104.009" y1="28.8024" x2="145.991" y2="28.8024">
          <stop  offset="0" :style="'stop-color:#' + ballColor[300]"/>
                  <stop  offset="0" :style="'stop-color:#' + ballColor[300]"/>
                  <stop  offset="0.4" :style="'stop-color:#' + ballColor[300]"/>
                  <stop  offset="0.7" :style="'stop-color:#' + ballColor[400]"/>
        </linearGradient>
                <path :style="'stroke:#' + ballColor[900]" style="fill:url(#SVGID_1_);stroke-width:10;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="M125,4.7c-11.7,12.1-18.7,24.2-21,36.4c0,15.9,42,15.9,42,0C143.7,28.9,136.7,16.8,125,4.7z"/>
                <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="110.5045" y1="88.0558" x2="139.4955" y2="88.0558">
          <stop  offset="0" :style="'stop-color:#' + ballColor[300]"/>
                  <stop  offset="0" :style="'stop-color:#' + ballColor[300]"/>
                  <stop  offset="0.4" :style="'stop-color:#' + ballColor[300]"/>
                  <stop  offset="0.7" :style="'stop-color:#' + ballColor[400]"/>
        </linearGradient>
                <path :style="'stroke:#' + ballColor[900]" style="fill:url(#SVGID_2_);stroke-width:10;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" d="M125,69.7c-8.1,8.4-12.9,16.7-14.5,25.1c9.7,15.5,19.3,15.5,29,0C137.9,86.4,133.1,78,125,69.7z"/>
                <circle :style="'fill:#' + ballColor[400]" cx="178.5" cy="63.5" r="4.5"/>
                <circle :style="'fill:#' + ballColor[400]" cx="73" cy="61" r="4"/>
                <path :style="'stroke:#' + ballColor[900]" style="fill:none;stroke-width:7;stroke-linecap:round;stroke-miterlimit:10;" d="M61.4,187.8c9.9,5,20,8.8,30.2,11.3c10.3,2.5,20.7,8.8,31.3,8.9c10.6,0,21.4-6.2,32.3-8.7
          c10.9-2.5,22-6.3,33.3-11.4"/>
      </svg>
      <svg
        v-else-if="type==='humanbear'"
        version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 250 250" style="enable-background:new 0 0 250 250;" xml:space="preserve"
        :class="{
          'ballBounce cursor-pointer block lg:block w-32 h-32': !isPreview,
          'cursor-pointer block lg:block': isPreview
        }"
      >
        <g>
          <g>
            <circle :style="'fill:#' + ballColor[300]" cx="45.5" cy="41.5" r="34.5"/>
          </g>
          <g>
            <g>
              <circle :style="'fill:#' + ballColor[300]" cx="205" cy="41.5" r="34.5"/>
            </g>
            <g>
              <circle :style="'fill:#' + ballColor[400]" cx="207.5" cy="40" r="16"/>
            </g>
          </g>
          <g>
            <circle :style="'fill:#' + ballColor[400]" cx="43" cy="40" r="16"/>
          </g>
          <g>
            <circle :style="'fill:#' + ballColor[400]" cx="125" cy="125.8" r="119.5"/>
          </g>
          <g>
            <ellipse :style="'fill:#' + ballColor[300]" cx="125" cy="108.3" rx="114" ry="102"/>
          </g>
          <g>
            <path :style="'fill:#' + skinColor[50]" d="M223.5,193.4v-61.7c0-15.8-12.9-28.6-28.6-28.6c-46.6,7-93.1,7-139.7,0c-15.8,0-28.6,12.9-28.6,28.6v61.7
              c32.8,37.9,65.7,51.9,98.5,51.9S190.7,231.3,223.5,193.4z"/>
            <path :style="'fill:#' + skinColor[900]" d="M194.9,103c-46.6,7-93.1,7-139.7,0c-15.8,0-28.6,12.9-28.6,28.6v9c0-15.8,12.9-28.6,28.6-28.6
              c46.6,7,93.1,7,139.7,0c15.8,0,28.6,12.9,28.6,28.6v-9C223.5,115.9,210.6,103,194.9,103z"/>
            <g>
              <path :style="'fill:#' + skinColor[900]" d="M97.4,134.1l11.7-20.3c0.7-1.3-0.2-2.8-1.6-2.8H84.1c-1.4,0-2.4,1.6-1.6,2.8l11.7,20.3
                C94.9,135.4,96.7,135.4,97.4,134.1z"/>
              <path :style="'fill:#' + skinColor[900]" d="M155.8,134.1l11.7-20.3c0.7-1.3-0.2-2.8-1.6-2.8h-23.4c-1.4,0-2.4,1.6-1.6,2.8l11.7,20.3
                C153.3,135.4,155.1,135.4,155.8,134.1z"/>
            </g>
            <g>
              <path style="fill:#FFFFFF;" d="M97.4,131.1l11.7-20.3c0.7-1.3-0.2-2.8-1.6-2.8H84.1c-1.4,0-2.4,1.6-1.6,2.8l11.7,20.3
                C94.9,132.4,96.7,132.4,97.4,131.1z"/>
              <path style="fill:#FFFFFF;" d="M155.8,131.1l11.7-20.3c0.7-1.3-0.2-2.8-1.6-2.8h-23.4c-1.4,0-2.4,1.6-1.6,2.8l11.7,20.3
                C153.3,132.4,155.1,132.4,155.8,131.1z"/>
            </g>
            <path :style="'stroke:#' + ballColor[900]" style="fill:none;stroke-width:6;stroke-miterlimit:10;" d="M223.5,193.4v-61.7c0-15.8-12.9-28.6-28.6-28.6c-46.6,7-93.1,7-139.7,0c-15.8,0-28.6,12.9-28.6,28.6v61.7
              c32.8,37.9,65.7,51.9,98.5,51.9S190.7,231.3,223.5,193.4z"/>
          </g>
          <g>
            <path :style="'fill:#' + ballColor[900]" d="M125,10c16.6,0,32.6,3.5,47.6,10.3l5.9,2.7l4.9-4.3c6-5.3,13.6-8.2,21.6-8.2c17.9,0,32.5,14.6,32.5,32.5
              c0,7.7-2.7,15.1-7.7,21l-4.2,5l2.8,5.8c7.6,15.7,11.5,32.6,11.5,50.2c0,30.7-12,59.6-33.7,81.3C184.6,228,155.7,240,125,240
              s-59.6-12-81.3-33.7C22,184.6,10,155.7,10,125c0-17.6,3.9-34.5,11.5-50.2l2.8-5.8l-4.2-5c-4.9-5.9-7.7-13.3-7.7-21
              c0-17.9,14.6-32.5,32.5-32.5c8,0,15.6,2.9,21.6,8.2l4.9,4.3l5.9-2.7C92.4,13.5,108.4,10,125,10 M125,0c-18.5,0-36,4-51.8,11.2
              C65.7,4.6,55.8,0.5,45,0.5C21.5,0.5,2.5,19.5,2.5,43c0,10.4,3.8,20,10,27.4C4.5,86.9,0,105.4,0,125c0,69,56,125,125,125
              s125-56,125-125c0-19.6-4.5-38.1-12.5-54.6c6.2-7.4,10-17,10-27.4c0-23.5-19-42.5-42.5-42.5c-10.8,0-20.7,4.1-28.2,10.7
              C161,4,143.5,0,125,0L125,0z"/>
          </g>
          <path style="opacity:0.75;fill:none;stroke:#FFFFFF;stroke-width:14;stroke-linecap:round;stroke-miterlimit:10;" d="M49,48.5c19-19,45.3-30.8,74.2-30.8"/>
          <path style="opacity:0.5;fill:none;stroke:#FFFFFF;stroke-width:14;stroke-linecap:round;stroke-miterlimit:10;" d="M209.4,192.4c2.2-2.8,4.3-5.7,6.3-8.7"/>
          <path style="opacity:0.5;fill:none;stroke:#FFFFFF;stroke-width:14;stroke-linecap:round;stroke-miterlimit:10;" d="M159.2,226.7c3.2-1,6.4-2.2,9.5-3.5c3.1-1.3,6.1-2.8,9.1-4.4c3-1.6,5.9-3.4,8.7-5.3c2.8-1.9,5.6-4,8.2-6.2"/>
          <g>
            <path :style="'stroke:#' + ballColor[900] + ';fill:#' + ballColor[900]" style="stroke-width:7.148;stroke-linecap:round;stroke-miterlimit:10;" d="M132.8,88.8c0,3.4-3.4,6.1-7.5,6.1c-4.1,0-7.5-2.7-7.5-6.1s3.4,0.4,7.5,0.4C129.4,89.2,132.8,85.4,132.8,88.8
              z"/>
            <line :style="'stroke:#' + ballColor[900]" style="fill:none;stroke-width:4.2888;stroke-linecap:round;stroke-miterlimit:10;" x1="125.2" y1="91.3" x2="125.2" y2="107"/>
          </g>
          <g>
            <path :style="'stroke:#' + ballColor[900]" style="fill:none;stroke-width:8.3455;stroke-linecap:round;stroke-miterlimit:10;" d="M104.3,188.1c0,0,20.7,21.9,41.3,0"/>
            <g>
              <ellipse :style="'fill:#' + ballColor[900]" cx="74.4" cy="157.2" rx="17.5" ry="20.3"/>
              <ellipse style="fill:#FFFFFF;" cx="73.9" cy="146" rx="7.8" ry="4.9"/>
              <path :style="'stroke:#' + ballColor[700]" style="fill:none;stroke-width:5.3846;stroke-linecap:round;stroke-miterlimit:10;" d="M68.4,168.1c0,0,5.3,5.6,11.4,0"/>
            </g>
            <g>
              <ellipse :style="'fill:#' + ballColor[900]" cx="175.6" cy="157.3" rx="17.5" ry="20.3"/>
              <ellipse style="fill:#FFFFFF;" cx="175.1" cy="146.1" rx="7.8" ry="4.9"/>
              <path :style="'stroke:#' + ballColor[700]" style="fill:none;stroke-width:5.3846;stroke-linecap:round;stroke-miterlimit:10;" d="M169.5,168.2c0,0,5.3,5.6,11.4,0"/>
            </g>
          </g>
        </g>
                <g>
          <g>
            <circle :style="'stroke:#' + ballColor[900]" style="fill:#FFFFFF;stroke-width:6;stroke-linecap:round;stroke-miterlimit:10;" cx="75" cy="71" r="22.5"/>
            <circle :style="'fill:#' + ballColor[900]" cx="75" cy="82.5" r="11"/>
            <circle :style="'stroke:#' + ballColor[900]" style="fill:#FFFFFF;stroke-width:6;stroke-linecap:round;stroke-miterlimit:10;" cx="175" cy="71" r="22.5"/>
            <circle :style="'fill:#' + ballColor[900]" cx="175" cy="82.5" r="11"/>
          </g>
        </g>
      </svg>

      <svg
        v-else
        version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 250 250" style="enable-background:new 0 0 250 250;" xml:space="preserve"
        :class="{
          'ballBounce cursor-pointer block lg:block w-32 h-32': !isPreview,
          'cursor-pointer block lg:block': isPreview
        }"
      >
        <g>
          <circle :style="'fill:#' + ballColor[400]" cx="125" cy="125.8" r="119.5"/>
        </g>
        <g>
          <ellipse :style="'fill:#' + ballColor[300]" cx="125" cy="108.3" rx="114" ry="102"/>
        </g>
        <g>
          <path :style="'fill:#' + ballColor[900]" class="ballStroke" d="M125,10c30.7,0,59.6,12,81.3,33.7S240,94.3,240,125s-12,59.6-33.7,81.3S155.7,240,125,240s-59.6-12-81.3-33.7
		S10,155.7,10,125s12-59.6,33.7-81.3S94.3,10,125,10 M125,0C56,0,0,56,0,125s56,125,125,125s125-56,125-125S194,0,125,0L125,0z"/>
        </g>
        <g name="reflection" class="faceLightRotate">
          <path style="opacity:0.25;fill:none;stroke:#FFFFFF;stroke-width:14;stroke-linecap:round;stroke-miterlimit:10;" d="M209.4,192.4c2.2-2.8,4.3-5.7,6.3-8.7"/>
          <path style="opacity:0.25;fill:none;stroke:#FFFFFF;stroke-width:14;stroke-linecap:round;stroke-miterlimit:10;" d="M159.2,226.7c3.2-1,6.4-2.2,9.5-3.5c3.1-1.3,6.1-2.8,9.1-4.4c3-1.6,5.9-3.4,8.7-5.3c2.8-1.9,5.6-4,8.2-6.2"/>
          <path style="opacity:0.5;fill:none;stroke:#FFFFFF;stroke-width:14;stroke-linecap:round;stroke-miterlimit:10;" d="M49,48.5c19-19,45.3-30.8,74.2-30.8"/>
        </g>
        <g class="blockActive">
          <g class="">
            <path style="fill:none;stroke-width:9.8995;stroke-linecap:round;stroke-miterlimit:10;" d="M100.5,162.3c0,0,24.5,26,49,0"
              :style="'stroke:#' + ballColor[900]"
            />
            <g class="faceEyesSmirk">
              <ellipse :style="'fill:#' + ballColor[900]" cx="65" cy="125.6" rx="26" ry="30.1"/>
              <ellipse style="fill:#FFFFFF;" cx="64.2" cy="108.9" rx="11.6" ry="7.3"/>
              <path style="fill:none;stroke-width:8;stroke-linecap:round;stroke-miterlimit:10;" d="M56,141.8c0,0,7.9,8.3,17,0"
                :style="'stroke:#' + ballColor[700]"
              />
              <ellipse :style="'fill:#' + ballColor[900]" cx="185" cy="125.8" rx="26" ry="30.1"/>
              <ellipse style="fill: #FFFFFF;" cx="184.2" cy="109.1" rx="11.6" ry="7.3"/>
              <path style="fill:none;stroke-width:8;stroke-linecap:round;stroke-miterlimit:10;" d="M176,142c0,0,7.9,8.3,17,0"
                :style="'stroke:#' + ballColor[700]"
              />
            </g>
          </g>
        </g>
      </svg>
    </template>
    <svg
      v-else
      class=" cursor-pointer w-32 h-32 ballZZZ"
      version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
     viewBox="0 0 250 250" style="enable-background:new 0 0 250 250;" xml:space="preserve"
    >
        <g>
          <circle :style="'fill:#' + ballColor[400]" cx="126" cy="125.8" r="119.5"/>
        </g>
      <g>
          <ellipse :style="'fill:#' + ballColor[300]" cx="126.5" cy="108.3" rx="114" ry="102"/>
        </g>
      <g>
          <path :style="'fill:#' + ballColor[500]" class="ballStroke" d="M125.4,10.1c30.7,0,59.6,12,81.3,33.7s33.7,50.6,33.7,81.3s-12,59.6-33.7,81.3c-21.7,21.7-50.6,33.7-81.3,33.7
            s-59.6-12-81.3-33.7c-21.7-21.7-33.7-50.6-33.7-81.3s12-59.6,33.7-81.3C65.8,22,94.7,10.1,125.4,10.1 M125.4,0.1
            c-69,0-125,56-125,125s56,125,125,125c69,0,125-56,125-125S194.5,0.1,125.4,0.1L125.4,0.1z"/>
        </g>
        <g name="reflection" class="faceLightRotateZZZ">
          <path style="opacity:0.25;fill:none;stroke:#FFFFFF;stroke-width:14;stroke-linecap:round;stroke-miterlimit:10;" d="M210.1,192.5c2.2-2.8,4.3-5.7,6.3-8.7"/>
          <path style="opacity:0.25;fill:none;stroke:#FFFFFF;stroke-width:14;stroke-linecap:round;stroke-miterlimit:10;" d="M159.9,226.7c3.2-1,6.4-2.2,9.5-3.5s6.1-2.8,9.1-4.4c3-1.6,5.9-3.4,8.7-5.3c2.8-1.9,5.6-4,8.2-6.2"/>
          <path style="opacity:0.5;fill:none;stroke:#FFFFFF;stroke-width:14;stroke-linecap:round;stroke-miterlimit:10;" d="M49.8,48.6c19-19,45.3-30.8,74.2-30.8"/>
        </g>

      <g class="faceZZZ">
        <g>
          <path d="M183.6,41.3h-11.2c-0.6,0-1.2-0.4-1.4-1s-0.1-1.2,0.3-1.7l8.6-8.6h-6.8c-0.9,0-1.6-0.7-1.6-1.6
              c0-0.9,0.7-1.6,1.6-1.6h10.6c0.6,0,1.2,0.4,1.4,1c0.2,0.6,0.1,1.2-0.3,1.7l-8.6,8.6h7.5c0.9,0,1.6,0.7,1.6,1.6
              C185.2,40.7,184.5,41.3,183.6,41.3z"
              :style="'fill:#' + ballColor[400]"></path>
        </g>
        <g>
          <path d="M161.8,56.8h-8.5c-0.6,0-1.2-0.4-1.4-1c-0.2-0.6-0.1-1.2,0.3-1.7l5.9-5.9h-4.3c-0.9,0-1.6-0.7-1.6-1.6
              c0-0.9,0.7-1.6,1.6-1.6h8c0.6,0,1.2,0.4,1.4,1c0.2,0.6,0.1,1.2-0.3,1.7l-5.9,5.9h4.8c0.9,0,1.6,0.7,1.6,1.6S162.6,56.8,161.8,56.8
              z"
              :style="'fill:#' + ballColor[400]">
          </path>
        </g>
        <g>
          <path d="M166.6,27.3h-6.2c-0.6,0-1.2-0.4-1.4-1c-0.2-0.6-0.1-1.2,0.3-1.7l3.6-3.6h-2.1c-0.9,0-1.6-0.7-1.6-1.6
              c0-0.9,0.7-1.6,1.6-1.6h5.9c0.6,0,1.2,0.4,1.4,1c0.2,0.6,0.1,1.2-0.3,1.7l-3.6,3.6h2.5c0.9,0,1.6,0.7,1.6,1.6
              C168.1,26.6,167.4,27.3,166.6,27.3z"
              :style="'fill:#' + ballColor[400]">
          </path>
        </g>
      </g>

      <g  class="ballFloat">
        <g class="ballFloatEyes">
          <g>
          <path style="fill:none;stroke-width:8.2184;stroke-miterlimit:10;"
            :style="'stroke:#' + ballColor[900] + ';'"
            d="M98.2,59.6c-4,13.1-16.5,20.8-27.7,17.3S53.4,60,57.4,47"/>
          </g>
          <g>
            <path
              style="fill:none;stroke:#253D89;stroke-width:10;stroke-miterlimit:10;"
              :style="'stroke:#' + ballColor[900] + ';'"
              d="M214.8,95.8c-4.9,15.9-20,25.3-33.7,21.1c-13.7-4.2-20.8-20.6-15.9-36.5"/>
          </g>
        </g>
        <g class="ballFloatMouth">
          <circle style="fill: #741446;" cx="113.2" cy="113.6" r="21"/>
          <path style="fill:#F15E39;" d="M92.9,118.7c2.3,9.1,10.5,15.9,20.3,15.9c4.8,0,9.2-1.6,12.7-4.3c-1.6-9.5-9.8-16.7-19.7-16.7
          C101.1,113.6,96.4,115.5,92.9,118.7z"/>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
  import {colors} from '../colors/colors.js';
  import {skins} from '../colors/skins.js';
  import anime from 'animejs/lib/anime.es.js';
  import { nextTick } from 'vue';

  export default {
    name: 'BlockFace',
    props: {
      isActive: Boolean,
      type: String,
      isPreview: Boolean,
      color: String,
      skin: String,
      performance: String,
      id: String
    },
    data() {
      return {
        colors,
        skins,
        isActiveFace: false,
        animate: null
      }
    },
    computed: {
      ballColor() {
        if (this.color) {
          let colorIndex = this.colors.findIndex(item => item.value === this.color);
          return this.colors[colorIndex].colors;
        } else {
          let colorIndex = Math.floor(Math.random() * this.colors.length);
          return this.colors[colorIndex].colors;
        }
      },
      skinColor() {
        if (this.skin) {
          let skinIndex = this.skins.findIndex(item => item.value === this.skin);
          return this.skins[skinIndex].colors;
        } else {
          let skinIndex = Math.floor(Math.random() * this.skins.length);
          return this.skins[skinIndex].colors;
        }
      }
    },
    methods: {
      animateWAAPI() {
        const elAnim = document.querySelector('#block-face-' + this.id + ' .ballBounce');
        this.animate = elAnim.animate(
            [
              {transform: 'translateX(0) translateY(0) translateZ(0) scaleX(1.1) scaleY(0.95) scaleZ(1)', easing: 'cubic-bezier(0,0,0.2,1)'},
              {transform: 'translateX(0) translateY(-30px) translateZ(0) scaleX(0.95) scaleY(1.05) scaleZ(1)', easing: 'cubic-bezier(0.8,0,1,1)'},
              {transform: 'translateX(0) translateY(0) translateZ(0) scaleX(1.1) scaleY(0.95) scaleZ(1)', easing: 'cubic-bezier(0,0,0.2,1)'},
            ], {
              duration: 900,
              iterations: Infinity,
            }
        );
      },
      animateZZZWAAPI() {
        const elAnim = document.querySelector('#block-face-' + this.id + ' .faceZZZ');
        elAnim.animate(
          [
            { transform: 'rotate(10deg) scale(1.2)' },
            { transform: 'rotate(0) scale(0.9)' },
            { transform: 'rotate(10deg) scale(1.2)' },
          ], {
            duration: 6000,
            iterations: Infinity,
            easing: 'ease-in-out',
          }
        );
        const elAnimBF = document.querySelector('#block-face-' + this.id + ' .ballFloat');
        elAnimBF.animate(
            [
              { transform: 'translateY(0px) rotate(-2deg)' },
              { transform: 'translateY(-20px) rotate(-8deg)' },
              { transform: 'translateY(0px) rotate(-2deg)' },
            ], {
              duration: 6000,
              iterations: Infinity,
              easing: 'ease-in-out',
            }
        );

        const elAnimBFM = document.querySelector('#block-face-' + this.id + ' .ballFloatMouth');
        elAnimBFM.animate(
            [
              { transform: 'scale(0.4) rotate(0)' },
              { transform: 'scale(1.3) rotate(10deg)' },
              { transform: 'scale(0.4) rotate(0)' },
            ], {
              duration: 6000,
              iterations: Infinity,
              easing: 'ease-in-out',
            }
        );

        const elAnimBFE = document.querySelector('#block-face-' + this.id + ' .ballFloatEyes');
        elAnimBFE.animate(
            [
              { transform: 'skewX(0)' },
              { transform: 'skewX(-10deg)' },
              { transform: 'skewX(0)' },
            ], {
              duration: 6000,
              iterations: Infinity,
              easing: 'ease-in-out',
            }
        );
      },
      animateZZZ() {
        anime({
          targets: '#block-face-' + this.id + ' .faceZZZ',
          keyframes: [
            {scale: 0.9, rotate: 0, duration: 0},
            {scale: 1.2, rotate: 10},
            {scale: 0.9, rotate: 0}
          ],
          loop: true,
          duration: 9000,
          direction: 'alternate',
          easing: 'easeInOutSine'
        });
        anime({
          targets: '#block-face-' + this.id + ' .faceLightRotateZZZ',
          keyframes: [
            {rotate: 5, easing: 'easeOutSine'},
            {rotate: 0, easing: 'easeInSine'},
            {rotate: -5, easing: 'easeOutSine'},
            {rotate: 0, easing: 'easeInSine'},
          ],
          loop: true,
          duration: 9000,
          easing: 'easeInOutSine'
        });
        anime({
          targets: '#block-face-' + this.id + ' .ballFloat',
          keyframes: [
            {translateY: 0, rotate: -2, duration: 0},
            {translateY: -20, rotate: -8},
            {translateY: 0, rotate: -2},
          ],
          loop: true,
          duration: 9000,
          easing: 'easeInOutSine'
        });
        anime({
          targets: '#block-face-' + this.id + ' .ballFloatMouth',
          keyframes: [
            {scale: 0.4, rotate: 0, duration: 0},
            {scale: 1.3, rotate: 10},
            {scale: 0.4, rotate: 0},
          ],
          loop: true,
          duration: 9000,
          easing: 'easeInOutSine'
        });
        anime({
          targets: '#block-face-' + this.id + ' .ballFloatEyes',
          keyframes: [
            {scaleX: 1, scaleY: 1, skewX: 0, duration: 0},
            {scaleX: 1, scaleY: 1, skewX: -10},
            {scaleX: 1, scaleY: 1, skewX: 0}
          ],
          loop: true,
          duration: 9000,
          easing: 'easeInOutSine'
        });
      },
      animateDeactivate() {
        anime(
            {
              targets: '#block-face-' + this.id + ' .ballStroke',
              fill: '#' + this.ballColor[500],
              easing: 'easeInOutSine',
              duration: 400,
              direction: 'linear',
              loop: false,
            });

        const animation_sleep = anime(
            {
              targets: '#block-face-' + this.id + ' .ballBounce',
              keyframes: [
                {
                  translateX: 0,
                  translateY: 0,
                  translateZ: 0,
                  scaleX: 1.1,
                  scaleY: 0.95,
                  scaleZ: 1,
                  easing: 'cubicBezier(.8,0,1,1)',
                  duration: 0,
                },
                {
                  translateX: 0,
                  translateY: -30,
                  translateZ: 0,
                  scaleX: 0.95,
                  scaleY: 1.05,
                  scaleZ: 1,
                  rotate: -360,
                  easing: 'cubicBezier(0,0,.2,1)',
                  duration: 200,
                },
                {
                  translateX: 0,
                  translateY: 0,
                  translateZ: 0,
                  scaleX: 0.95,
                  scaleY: 0.95,
                  scaleZ: 1,
                  easing: 'cubicBezier(.8,0,1,1)',
                  duration: 200,
                },
              ],
              loop: false,
            }
        );
        animation_sleep.finished.then(()=> {
          this.isActiveFace = false;
          anime.remove('#block-face-' + this.id + ' .ballBounce');
          nextTick(() => {
            if (this.performance === 'high') {
              this.animateZZZ();
            }
          });
        });
      },
      animateActive(skipInit = false) {
        let animation = null;

        if (!skipInit) {
          const xMax = 8;
          anime(
              {
                targets: '#block-' + this.id,
                backgroundColor: [
                  {
                    value: '#' + this.ballColor[50],
                  },
                  {
                    value: '#ffffff',
                  },
                  {
                    value: '#' + this.ballColor[100],
                  },
                  {
                    value: '#ffffff',
                  },
                  {
                    value: '#' + this.ballColor[100],
                  }
                ],
                borderColor: [
                  {
                    value: '#' + this.ballColor[500],
                  },
                  {
                    value: '#' + this.ballColor[600],
                  },
                  {
                    value: '#' + this.ballColor[700],
                  },
                  {
                    value: '#' + this.ballColor[800],
                  },
                  {
                    value: '#' + this.ballColor[900],
                  }
                ],
                translateY: [
                  {
                    value: xMax * -1,
                  },
                  {
                    value: xMax,
                  },
                  {
                    value: xMax / -2,
                  },
                  {
                    value: xMax / 2,
                  },
                  {
                    value: 0,
                  }
                ],
                easing: 'easeInOutSine',
                delay: 300,
                duration: 400,
                complete: (anim) => {
                  if (anim.animatables.length) {
                    anim.animatables[0].target.style.removeProperty('border-color');
                    anim.animatables[0].target.style.removeProperty('background-color');
                  }
                }
              });
          anime(
              {
                targets: '#block-face-' + this.id + ' .ballFloat',
                rotate: -15,
                translateX: 32,
                translateY: 40,
                duration: 200,
              });
          anime(
              {
                targets: '#block-face-' + this.id + ' .ballStroke',
                fill: '#' + this.ballColor[900],
                easing: 'easeInOutSine',
                duration: 400,
                direction: 'linear',
                loop: false,
              });
          anime({
            targets: ['#block-face-' + this.id + ' .ballFloatEyes', '#block-face-' + this.id + ' .ballFloatMouth'],
            keyframes: [
              {translateY: 70}
            ],
            loop: false,
            delay: 200,
            duration: 200,
            easing: 'easeInOutSine'
          });

          animation = anime(
              {
                targets: '#block-face-' + this.id + ' .ballZZZ',
                keyframes: [
                  {
                    translateX: 0,
                    translateY: 0,
                    translateZ: 0,
                    scaleX: 0.1,
                    scaleY: 0.1,
                    scaleZ: 0.1,
                    easing: 'cubicBezier(.8,0,1,1)',
                    duration: 0,
                  },
                  {
                    translateX: 0,
                    translateY: -30,
                    translateZ: 0,
                    scaleX: 0.95,
                    scaleY: 1.05,
                    scaleZ: 1,
                    rotate: 360,
                    easing: 'cubicBezier(0,0,.2,1)',
                    duration: 200,
                  },
                  {
                    translateX: 0,
                    translateY: 0,
                    translateZ: 0,
                    scaleX: 1.1,
                    scaleY: 0.95,
                    scaleZ: 1,
                    easing: 'cubicBezier(.8,0,1,1)',
                    duration: 200,
                  },
                ]
              });
        } else {
          animation = anime({
            targets: '#block-' + this.id,
            duration: 0
          });
        }

        animation.finished.then(()=> {
          this.isActiveFace = true;
          nextTick(() => {
            if (this.performance !== 'low') {
              this.animateWAAPI();
            }
          });
        });
      }
    },
    watch: {
      isActive(val) {
        if (val === false) {
          if (this.animate !== null) {
            this.animate.cancel();
          }
          this.animateDeactivate();
        } else {
          nextTick(() => {
            this.animateActive();
          });
        }
      },
      type() {
        if (this.isActive) {
          nextTick(() => {
            this.animateActive(true);
          });
        }
      }
    },
    mounted() {
      if (this.isActive === true) {
        this.isActiveFace = true;
        if (this.performance === 'high' || this.performance === 'medium') {
          this.animateActive();
        }
      } else {
        //this.animateZZZ();
        if (this.performance === 'high') {
          this.animateZZZWAAPI();
        }
      }
    }
  }
</script>

<style>
  .ballFloat {
    transform-origin: bottom;
  }
  .faceZZZ, .ballFloatMouth, .ballFloatEyes {
    transform-origin: 120px;
  }
  .faceLightRotateZZZ {
    transform-origin: center;
  }
</style>
